import { forwardRef, PropsWithChildren } from "react";
import { InputErrorMessage } from "components/input-error-message";
import { InputLabel } from "components/input-label";
import Icon from "components/icons";
import { Spacer } from "components/spacer/spacer.component";
import { SCont, SInpIcon, SInput, SLabel, SNotifyText, STextarea, SWrap } from "./styles";

interface IMTSInputProps {
  type?: string;
  placeholder?: string;
  label?: string;
  searchIcon?: boolean;
  errorMessage?: string | undefined;
  notifyMessage?: string | undefined;
  [x: string]: any;
  touched: boolean;
  size?: "S" | "M";
  className?: string;
  disabled?: boolean;
}

export const MTSInput = forwardRef<HTMLDivElement, PropsWithChildren<IMTSInputProps>>(
  (props, ref) => {
    const {
      label = "",
      searchIcon,
      type = "text",
      placeholder = "",
      errorMessage,
      notifyMessage,
      touched,
      size = "M",
      className,
      disabled = false,
      ...rest
    } = props;

    return (
      <SWrap ref={ref} className={className}>
        {label ? (
          <>
            <SLabel>
              <InputLabel label={label} disabled={disabled} hasError={Boolean(errorMessage)} />
            </SLabel>
            <Spacer value="4px" />
          </>
        ) : null}
        <SCont>
          {searchIcon ? <Icon.Search className="icon--search" /> : null}
          {type === "textarea" ? (
            <STextarea
              placeholder={placeholder}
              rows={1}
              wrap="soft"
              spellCheck
              hasError={Boolean(errorMessage)}
              disabled={disabled}
              {...rest}
            />
          ) : (
            <SInput
              autoComplete="off"
              type={type}
              placeholder={placeholder}
              hasError={Boolean(errorMessage)}
              sizing={size}
              withSearch={searchIcon}
              disabled={disabled}
              {...rest}
            />
          )}

          {touched || errorMessage ? (
            <SInpIcon>
              {errorMessage ? (
                <Icon.InputError sx={{ width: 18, height: 18 }} />
              ) : (
                <Icon.SuccessShaped sx={{ fontSize: 18 }} />
              )}
            </SInpIcon>
          ) : null}
        </SCont>
        {errorMessage ? (
          <InputErrorMessage errorMessage={errorMessage} />
        ) : notifyMessage ? (
          <>
            <Spacer value="4px" />
            <SNotifyText variant="c1_regular" font="comp">
              {notifyMessage}
            </SNotifyText>
          </>
        ) : null}
      </SWrap>
    );
  },
);
