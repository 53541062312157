import Admin from "./Admin";
import Analytics from "./Analytics";
import Anomalies from "./Anomalies";
import ArrowBack from "./ArrowBack";
import ArrowDown from "./ArrowDown";
import ArrowDownMin from "./ArrowDownMin";
import ArrowHeadDown from "./ArrowHeadDown";
import ArrowHeadLeft from "./ArrowHeadLeft";
import ArrowHeadRight from "./ArrowHeadRight";
import ArrowLeft from "./ArrowLeft";
import ArrowRight from "./ArrowRight";
import ArrowUp from "./ArrowUp";
import ArrowUpMin from "./ArrowUpMin";
import Askue from "./Askue";
import AskueCard from "./AskueCard";
import Bitcoin from "./Bitcoin";
import Check from "./Check";
import Close from "./Close";
import CompanyLogo from "./CompanyLogo";
import Copy from "./Copy";
import Crown from "./Crown";
import Download from "./Download";
import Error from "./Error";
import Ellipsis from "./Ellipsis";
import Filter from "./Filter";
import GeoPosition from "./GeoPosition";
import GeoPositionMts from "./GeoPositionMts";
import Info from "./Info";
import Information from "./Information";
import InputError from "./InputError";
import InputOk from "./InputOk";
import Layer from "./Layer";
import Lock from "./Lock";
import List from "./List";
import LogOut from "./LogOut";
import MobileMenuBtn from "./MobileMenuBtn";
import Eye from "./Eye";
import PDF from "./PDF";
import Plus from "./Plus";
import Quit from "./Quit";
import Search from "./Search";
import Security from "./Security";
import Settings from "./Settings";
import Selection from "./Selection";
import Sort from "./Sort";
import Spinner from "./Spinner";
import Star from "./Star";
import Success from "./Success";
import SuccessShaped from "./SuccessShaped";
import Support from "./Support";
import UserAdd from "./UserAdd";
import Warn from "./Warn";
import CheckShaped from "./CheckShaped";
import ErrorShaped from "./ErrorShaped";
import CheckBorder from "./CheckBorder";
import Archive from "./Archive";
import Data from "./Data";
import Home from "./Home";
import Update from "./Update";
import Minus from "./Minus";
import Navi from "./Navi";

const Icon = {
  Admin,
  Analytics,
  Anomalies,
  Archive,
  ArrowBack,
  ArrowDown,
  ArrowDownMin,
  ArrowHeadDown,
  ArrowHeadLeft,
  ArrowHeadRight,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowUpMin,
  Askue,
  AskueCard,
  Bitcoin,
  Check,
  CheckShaped,
  CheckBorder,
  Close,
  CompanyLogo,
  Copy,
  Crown,
  Data,
  Download,
  Error,
  ErrorShaped,
  Ellipsis,
  Eye,
  Filter,
  GeoPosition,
  GeoPositionMts,
  Info,
  Information,
  InputError,
  InputOk,
  Layer,
  Lock,
  List,
  LogOut,
  MobileMenuBtn,
  PDF,
  Plus,
  Search,
  Quit,
  Security,
  Settings,
  Spinner,
  Star,
  Selection,
  Sort,
  Success,
  SuccessShaped,
  Support,
  UserAdd,
  Warn,
  Home,
  Update,
  Minus,
  Navi,
};

export default Icon;
