export const TABLE_SORT_DEFAULT_ABNT_SN = [
  {
    accessor: "sn",
    text: "Серийный номер",
  },
  {
    accessor: "meter_name",
    text: "Модель",
  },
  {
    accessor: "address",
    text: "Адрес",
  },
];
