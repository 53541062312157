import { FC, Fragment, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { useDebounce } from "hooks/useDebounce";
import { useManagementTenants } from "hooks/api/management";
import { useAddAbonentDevice, useGetAbonentStatus, useCheckSn } from "hooks/api/abonent";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { NotifyBlock } from "components/notification-block/notification-block";
import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifySection } from "components/notification-section/notification-section";
import { Loader } from "components/loader/loader";
import { makeDropdownFromTenants } from "../user/validation-schema";
import { defaultValues, schema } from "./validation-schema";
import { EStatus } from "./utils";
import Icon from "components/icons";
import { Toast } from "components/toast/toast";
import { checkEnabled, getIdByTenantName } from "./const";
import {
  SAddNew,
  SBtns,
  SDelIcon,
  SForm,
  SFormField,
  SInp,
  SNotify,
  SSubtitle,
  STop,
} from "./styles";

export const FormConnectDevice: FC = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sn",
  });

  const watchSn = watch("sn");
  const watchTenant = watch("tenant");
  const debouncedSn = useDebounce(JSON.stringify(watchSn), 500);
  const debouncedTenant = useDebounce(watchTenant, 500);

  const tenantsData = useManagementTenants();
  const abonentStatus = useGetAbonentStatus();
  const mutation = useAddAbonentDevice();
  const { data, isLoading, refetch } = useCheckSn(
    {
      tenant_id: getIdByTenantName(tenantsData?.data ?? [], debouncedTenant),
      sn: JSON.parse(debouncedSn).map((a) => Number(a.name)),
    },
    checkEnabled(JSON.parse(debouncedSn)) && !!debouncedTenant,
  );
  const isSuccessSn = data?.is_present === true;

  const onSubmit = async (submData) => {
    mutation.mutate({
      tenant_id: getIdByTenantName(tenantsData?.data ?? [], submData.tenant),
      contract_num: submData.contract_num,
      username: submData.username,
      sn: submData.sn.map((e) => e.name),
    });
  };

  useEffect(() => {
    if (debouncedSn && debouncedTenant) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSn, debouncedTenant]);

  useEffect(() => {
    const filledRequiredFields =
      debouncedTenant.length > 0 &&
      JSON.parse(debouncedSn).filter((a) => a.name && a.name.length > 0).length > 0;

    if (!isSuccessSn && filledRequiredFields && !isLoading) {
      toastError("Серийный номер не найден");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, debouncedSn, isSuccessSn]);

  return (
    <>
      {isLoading ? <Loader isFullPage /> : null}
      {abonentStatus.data?.status === EStatus.success || mutation?.isSuccess ? (
        <SNotify>
          <NotifySection
            title="Заявка успешно отправлена"
            subtitle={`Мы в процессе обработки заявки на подключение устройств. \nСроки рассмотрения заявки обычно занимают 1-2 рабочих дня.`}
          />
        </SNotify>
      ) : mutation?.isError ? (
        <SNotify>
          <NotifySection
            title="Произошла ошибка"
            subtitle="Что-то пошло не так, попробуйте еще раз"
            isError
          />
          <Spacer value="24px" />
          <div>
            <MTSButton size="M" variant="secondary" onClick={() => window.location.reload()}>
              Попробовать еще
            </MTSButton>
          </div>
        </SNotify>
      ) : (
        <>
          <STop>
            <CusTypo variant="h2_bold" font="wide">
              Заявка на подключение устройства
            </CusTypo>
            <Spacer value="16px" />
            {abonentStatus.data?.status === EStatus.error ? (
              <NotifyBlock
                content="Предыдущая заявка была отклонена.  Возможно, были указаны неверные данные. Пожалуйста, попробуйте отправить заявку еще раз."
                status="error"
              />
            ) : (
              <SSubtitle variant="p4_regular">
                Для продолжения работы с платформой необходимо отправить заявку на подключение
                устройства учета электроэнергии.
              </SSubtitle>
            )}
          </STop>
          <Spacer value="48px" />
          <SForm onSubmit={handleSubmit(onSubmit)}>
            <SFormField>
              {tenantsData.isLoading ? (
                <Loader />
              ) : (
                <MTSAutocomplete
                  label="Название организации"
                  placeholder=""
                  options={makeDropdownFromTenants(
                    tenantsData?.data?.filter((item) => {
                      return item.services.some(
                        (service) => service.service_type === "Облачная платформа учета",
                      );
                    }),
                  )}
                  control={control}
                  size="M"
                  {...register("tenant")}
                  notifyMessage="Укажите точное название организации, как указано в договоре"
                  errorMessage={errors?.tenant?.message}
                />
              )}
            </SFormField>
            <SFormField>
              <MTSInput
                label="Номер договора"
                {...register("contract_num", {
                  required: true,
                })}
                control={control}
                errorMessage={errors?.contract_num?.message}
              />
            </SFormField>
            <SFormField>
              <MTSInput
                label="ФИО"
                control={control}
                {...register("username", {
                  required: true,
                })}
                notifyMessage="Введите данные указанные в договоре"
                errorMessage={errors?.username?.message}
              />
            </SFormField>
            {fields.map((field, index) => (
              <Fragment key={field.id}>
                <SInp>
                  <MTSInput
                    label="Серийный номер устройства"
                    control={control}
                    {...register(`sn.${index}.name`, { required: index === 0 })}
                    notifyMessage="Введите серийный номер указанный на вашем счетчике"
                    errorMessage={errors?.sn?.[index]?.name.message}
                  />
                  {index !== 0 ? (
                    <SDelIcon
                      onClick={() => remove(index)}
                      isError={!!errors?.sn?.[index]?.name.message}
                    >
                      <Icon.Close sx={{ width: 20 }} />
                    </SDelIcon>
                  ) : null}
                </SInp>
                {index === fields.length - 1 && (
                  <SAddNew>
                    <MTSButton
                      size="S"
                      variant="secondary"
                      onClick={() => {
                        append({
                          name: "",
                        });
                      }}
                      icon={<Icon.Plus sx={{ width: 14, height: 14 }} />}
                      disabled={index !== 0 && !watchSn[index].name.length}
                    >
                      Новое устройство
                    </MTSButton>
                  </SAddNew>
                )}
              </Fragment>
            ))}
            <Spacer value="8px" />
            <SBtns>
              <MTSButton
                size="M"
                variant="primary"
                type="submit"
                onClick={() => {}}
                disabled={!(isValid && isSuccessSn)}
              >
                Отправить
              </MTSButton>
            </SBtns>
          </SForm>
        </>
      )}
    </>
  );
};

const toastError = (str: string) => {
  toast(<Toast title={str} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
