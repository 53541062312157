import { useMutation, useQuery } from "react-query";
import { queryClient as instance } from "react-query-wrapper";

import {
  IFuncUpdateTenant,
  deleteTenantById,
  getAddTenant,
  getManagementTenants,
  getTenantById,
  updateTenant,
} from "services/management-tenant";
import {
  IAddUserRequest,
  ITariffRes,
  IDemoChartResponse,
  IDemoCreateRequest,
  IDemoDeleteRequest,
  IDemoFileUploadResponse,
  IDemoFormUploadRequest,
  IDemoManagementResponse,
  IDemoMngScoreResponse,
  IDemoMngUpdStatusRequest,
  IDemoScoreResponse,
  IEventsResponse,
  IMenegementBilling,
  IMenegementTenantResponse,
  IShopProduct,
  IUpdateProdCardRequest,
  IUserResponse,
  ITariffReq,
  IMngAbonentResponse,
  IAbonentSn,
  IUpdateAbonentRequest,
  IUpdateStatusAppRequest,
} from "interface/management";
import {
  deleteDemoTenantById,
  demoAskueCreate,
  demoMngCreate,
  demoMngUpdateStatus,
  getDemoMngScore,
  getManagmentDemo,
} from "services/management-demo";
import {
  billingAddTariff,
  getBillingTariff,
  getManagementBilling,
  getXlsxBilling,
} from "services/management-billing";
import {
  IFuncUpdateUser,
  deleteUserById,
  getAddUser,
  getManagementUsers,
  getUserById,
  getUserTenants,
  updateUser,
} from "services/management-users";
import { demoUploadForm, demoUploadTable, getDemoChart, getDemoScore } from "services/demo";
import { getManagementEvents } from "services/management-events";
import { authChangeTenant } from "services/auth";
import {
  getShopCatalogue,
  IProdVisReq,
  changeProductVisibility,
  updateProduct,
} from "services/management-shop";
import { IDemoAskueCreateRequest } from "interface/askue";
import { toastEditUserErr, toastEditUserSucc } from "components/forms/user/form-edit-user";
import { toastDelUserErr, toastDelUserSucc } from "components/forms/user/form-delete-user";
import {
  deleteAbonent,
  deleteAbonentSn,
  getManagementAbonents,
  updateAbonent,
  updateStatusApplication,
} from "services/management-abonent";
import { toastInfoErr, toastInfoSucc } from "components/forms/abonent-management/const";
import { Keys } from "./const";

export function useManagementBilling() {
  return useQuery<any, any, IMenegementBilling[]>([Keys.MANAGEMENT_BILLING], () =>
    getManagementBilling(),
  );
}

export function useManagementTenants(isOn = true) {
  return useQuery<any, any, IMenegementTenantResponse[]>(
    [Keys.TENANT],
    () => getManagementTenants(),
    { enabled: isOn },
  );
}

export function useGetTenant(id: string | number) {
  return useQuery<any, any, IMenegementTenantResponse>([Keys.TENANT, id], () => getTenantById(id));
}

export function useAddTenant() {
  const mutationForm = useMutation((a: any) => getAddTenant(a), {
    onSuccess: async () => {
      await instance.invalidateQueries([Keys.TENANT]);
    },
  });

  return mutationForm;
}

export function useMutationDeleteTenant(id: string) {
  return useMutation(() => deleteTenantById(id));
}

export function useMutationDeleteDemoTenant() {
  return useMutation<any, any, IDemoDeleteRequest>((a) => deleteDemoTenantById(a));
}

export const invalidateManagmentTenants = async () => {
  await instance.invalidateQueries([Keys.TENANT], { exact: true });
};

export function useManagementUsers() {
  return useQuery<any, any, IUserResponse[]>([Keys.USERS], () => getManagementUsers());
}

export function useAddUser(withTenantRefresh?: boolean) {
  return useMutation<any, any, IAddUserRequest>((a) => getAddUser(a), {
    onSuccess: async () => {
      withTenantRefresh
        ? await instance.invalidateQueries([Keys.TENANT])
        : await instance.invalidateQueries([Keys.USERS]);
    },
  });
}

export function useMutationDeleteUser(id: string | number) {
  const mutationCreate = useMutation(() => deleteUserById(id), {
    onSuccess: async () => {
      toastDelUserSucc();
      await instance.invalidateQueries([Keys.USERS]);
    },
    onError: () => {
      toastDelUserErr();
    },
  });

  return mutationCreate;
}

export function useGetUser(id: string | number) {
  return useQuery([Keys.USERS, id], () => getUserById(id), { staleTime: 3000 });
}

export function useUpdateUser() {
  return useMutation<any, any, IFuncUpdateUser>((a) => updateUser(a), {
    onSuccess: async (res, req) => {
      toastEditUserSucc();
      await instance.refetchQueries([Keys.USERS], { exact: true });
    },
    onError: () => {
      toastEditUserErr();
    },
  });
}

export function useUpdateTenant() {
  return useMutation<any, any, IFuncUpdateTenant>((a) => updateTenant(a), {
    onSuccess: async (res, req) => {
      await instance.refetchQueries([Keys.TENANT], { exact: true });
      await instance.invalidateQueries([Keys.TENANT, req.id]);
      await instance.invalidateQueries([Keys.USERS], { exact: true });
    },
  });
}

export function useDemoScore(isAllowed: boolean) {
  return useQuery<any, any, IDemoScoreResponse>([Keys.DEMO, "score"], () => getDemoScore(), {
    enabled: isAllowed,
  });
}

export function useDemoChart(id: string | number) {
  return useQuery<any, any, IDemoChartResponse[]>(
    [Keys.DEMO, "chart", id],
    () => getDemoChart(id),
    {
      enabled: +id > 0,
    },
  );
}

export function useDemoUploadSample() {
  return useMutation<any, any, IDemoFormUploadRequest>((a) => demoUploadForm(a));
}

export function useDemoUploadTable() {
  return useMutation<IDemoFileUploadResponse[], any, FormData>((a) => demoUploadTable(a));
}

export function useManagementDemo() {
  const query = useQuery<any, any, IDemoManagementResponse[]>([Keys.DEMO, "management"], () =>
    getManagmentDemo(),
  );

  return query;
}

export const invalidateManagementDemo = async () => {
  await instance.invalidateQueries([Keys.DEMO, "management"], { exact: true });
};

export function useDemoMngScore(id: string | number, isOpen) {
  return useQuery<any, any, IDemoMngScoreResponse>(
    [Keys.DEMO, id, "management"],
    () => getDemoMngScore(id),
    {
      enabled: isOpen,
    },
  );
}

export function useDemoMngUpdateStatus() {
  return useMutation<any, any, IDemoMngUpdStatusRequest>((a) => demoMngUpdateStatus(a));
}

export function useCreateDemoAcc() {
  return useMutation<any, any, IDemoCreateRequest>((a) => demoMngCreate(a), {
    onSuccess: async () => {
      await instance.invalidateQueries([Keys.DEMO, "management"], { exact: true });
    },
  });
}

export function useManagementEvents() {
  return useQuery<any, any, IEventsResponse[]>([Keys.EVENTS, "events"], () =>
    getManagementEvents(),
  );
}

interface ISpecTenants {
  title: string;
  tenant_id: string;
}

export function useUserTenants(isEnabled: boolean) {
  return useQuery<any, any, ISpecTenants[]>([Keys.USERS, "tenants"], () => getUserTenants(), {
    enabled: isEnabled,
  });
}

export function useAuthChangeTenant() {
  return useMutation<any, any, string>((a) => authChangeTenant(a), {
    mutationKey: [Keys.AUTH, "change_tenant"],
  });
}

export function useShopCatalog() {
  return useQuery<any, any, IShopProduct[]>([Keys.SHOP], () => getShopCatalogue());
}

export function useChangeVisibility() {
  return useMutation<any, any, IProdVisReq>((a) => changeProductVisibility(a), {
    onSuccess: async (res, req) => {
      await instance.invalidateQueries([Keys.SHOP], { exact: true });
    },
  });
}

export function useUpdateProdCard() {
  return useMutation<any, any, IUpdateProdCardRequest>((a) => updateProduct(a));
}

export const invalidateManagmentShop = async () => {
  await instance.invalidateQueries([Keys.SHOP], { exact: true });
};

export function useCreateDemoAskue() {
  return useMutation<any, any, IDemoAskueCreateRequest>((a) => demoAskueCreate(a), {
    onSuccess: async () => {
      await instance.invalidateQueries([Keys.ASKUE], { exact: true });
    },
  });
}

export function useDownloadBillXlsx() {
  return useMutation<any, any, number>((id) => getXlsxBilling(id), {
    onSuccess: async (res) => {
      const url = window.URL.createObjectURL(res);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${res.filename}`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  });
}

export function useBillingTariff() {
  return useQuery<any, any, ITariffRes[]>([Keys.MANAGEMENT_BILLING, "tariff"], () =>
    getBillingTariff(),
  );
}

export function useCreateTariff() {
  return useMutation<any, any, ITariffReq>((a) => billingAddTariff(a), {
    onSuccess: async () => {
      await instance.invalidateQueries([Keys.MANAGEMENT_BILLING, "tariff"], { exact: true });
    },
  });
}

export function useManagementAbonents() {
  return useQuery<any, any, IMngAbonentResponse[]>([Keys.ABONENT], () => getManagementAbonents());
}

export function useMutationDeleteAbonentSn(data: IAbonentSn) {
  return useMutation<any, any, any>(() => deleteAbonentSn(data), {
    onSuccess: async (res, req) => {
      toastInfoSucc();
      await instance.invalidateQueries([Keys.ABONENT], { exact: true });
    },
    onError: () => {
      toastInfoErr();
    },
  });
}

export function useUpdateAbonent() {
  return useMutation<any, any, IUpdateAbonentRequest>((a) => updateAbonent(a), {
    onSuccess: async (res, req) => {
      toastInfoSucc();
      await instance.invalidateQueries([Keys.ABONENT], { exact: true });
    },
    onError: () => {
      toastInfoErr();
    },
  });
}

export function useUpdateStatusApplication() {
  return useMutation<any, any, IUpdateStatusAppRequest>((a) => updateStatusApplication(a), {
    onSuccess: async () => {
      await instance.invalidateQueries([Keys.ABONENT]);
    },
  });
}

export function useMutationDeleteAbonent() {
  return useMutation<any, any, any>((id) => deleteAbonent(id), {
    onSuccess: async (res, req) => {
      toastInfoSucc();
      await instance.invalidateQueries([Keys.ABONENT], { exact: true });
    },
    onError: () => {
      toastInfoErr();
    },
  });
}
