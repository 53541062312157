import { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { formatISO } from "date-fns";
import theme from "theme";

import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { getSchema } from "./validation-schema";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { IAskue, IAskueCounterRequest } from "interface/askue";
import { useAskueGetAbonReportXlsx } from "hooks/api/askue";
import { Toast } from "components/toast/toast";
import { defaultPeriod } from "./const";
import { SBtns, SDash, SForm, SPos } from "./styles";

interface IProp {
  devices: IAskue[];
}

export const FormDownloadReport: React.FC<IProp> = ({ devices }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(getSchema(devices.length > 1)),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const [period, setPeriod] = useState<any>(defaultPeriod);

  const { mutate: getXlsx } = useAskueGetAbonReportXlsx();

  const handleDatesPeriod = (date, index) => {
    setPeriod((prevPeriod) => {
      let newPeriod = [...prevPeriod];
      newPeriod[index] = formatISO(date);
      return newPeriod;
    });
  };

  const getDevicesData = (devicesData: IAskue[], selectedDevice: string[]) => {
    const serialNumbers = devicesData.length === 1 ? devicesData.map((a) => a.sn) : selectedDevice;

    return serialNumbers
      .map((sn) => {
        const device = devicesData.find((item) => item.sn === sn);
        if (device) {
          return { id_channel: Number(device.id_channel_actepr), sn: Number(device.sn) };
        }
        return null;
      })
      .filter((item) => item !== null) as IAskueCounterRequest[];
  };

  const onSubmit = async (submData) => {
    getXlsx(
      {
        counters: getDevicesData(devices, submData.devices),
        discreteness: "День",
        period: period,
      },
      {
        onError: (e) => {
          toastError(e?.description ?? "Ошибка загрузки");
        },
      },
    );
  };

  return (
    <>
      <SForm onSubmit={handleSubmit(onSubmit)}>
        {devices.length > 1 ? (
          <div className="devices">
            <MTSAutocomplete
              label="Устройства"
              placeholder=""
              options={(devices || []).map((a) => a.sn)}
              control={control}
              size="S"
              variant="checkbox"
              limitTags={0}
              {...register("devices", {
                required: true,
              })}
              errorMessage={errors?.devices?.message}
            />
          </div>
        ) : null}
        <div className="diskret">
          <MTSAutocomplete
            label="Дискретность"
            placeholder="День"
            options={["День"]}
            control={control}
            size="S"
            {...register("discreteness", {
              required: true,
            })}
            defaultValue={"День"}
            disabled={true}
            errorMessage={errors?.discreteness?.message}
          />
        </div>
        <div className="period">
          <CusTypo variant="p4_regular" styles={{ color: theme.mtsColor.text.secondary.lightMode }}>
            Период
          </CusTypo>
          <Spacer value="4px" />
          <SPos>
            <InputDatePicker
              placeholder="ДД.ММ.ГГ"
              onSelect={(e) => handleDatesPeriod(e, 0)}
              isPickAM
            />
            <SDash>
              <CusTypo variant="p4_bold">-</CusTypo>
            </SDash>
            <InputDatePicker
              placeholder="ДД.ММ.ГГ"
              onSelect={(e) => handleDatesPeriod(e, 1)}
              isPickAM
            />
          </SPos>
        </div>
        <SBtns>
          <MTSButton
            size="S"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={devices.length === 1 ? false : !isValid}
          >
            Скачать отчет
          </MTSButton>
        </SBtns>
      </SForm>
    </>
  );
};

const toastError = (str: string) => {
  toast(<Toast title={str} isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
