export const query_objects_limit = 1000;

export enum Keys {
  DEVICE_LIST = "api/list",
  DEMO = "api/demo",
  AUTH = "api/auth",
  MANAGEMENT_BILLING = " api/billing",
  TENANT = "api/tenant",
  USERS = "api/users",
  RESEARCH = "api/analytics",
  EVENTS = "api/user_activity",
  MAP_DEPARTURES = "api/counter_coordinates",
  MINING = "api/mining",
  ASKUE = "api/energosphere",
  TASKS = "api/tasks",
  SHOP = "api/shop",
  ABONENT = "api/abonent",
}
