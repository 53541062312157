import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  typographyAlignStyles: {
    display: "flex",

    alignItems: "center",
    position: "relative",
  },
  typographyHidden: {
    visibility: "hidden",
  },
  gap6: {
    gap: "6px",
  },
  gap10: {
    gap: "10px",
  },
  gap12: {
    gap: "12px",
  },
  btnSizeS: {
    "&.MuiButton-root": {
      padding: "6px 12px",
      borderRadius: "6px",
    },
    "&$iconBtn": {
      width: "32px",
      height: "32px",
      "&.MuiButton-root": {
        padding: "3px",
      },
    },
    "&$iconRoundBtn": {
      width: "32px",
      height: "32px",
      "&.MuiButton-root": {
        padding: "3px",
      },
    },
  },
  btnSizeM: {
    "&.MuiButton-root": {
      padding: "10px 24px",
      borderRadius: "8px",
    },
    "&$iconBtn": {
      "&.MuiButton-root": {
        padding: "10px",
      },
    },
    "&$iconRoundBtn": {
      width: "46px",
      height: "44px",
      "&.MuiButton-root": {
        padding: "10px",
      },
    },
  },
  btnSizeL: {
    "&.MuiButton-root": {
      padding: "14px 32px",
      borderRadius: "8px",
    },
    "&$iconBtn": {
      "&.MuiButton-root": {
        padding: "14px",
      },
    },
    "&$iconRoundBtn": {
      width: "54px",
      height: "54px",
      "&.MuiButton-root": {
        padding: "14px",
      },
    },
  },
  btnSizeXL: {
    "&.MuiButton-root": {
      padding: "24px 40px",
      borderRadius: "10px",
    },
    "&$iconBtn": {
      "&.MuiButton-root": {
        padding: "24px",
      },
    },
    "&$iconRoundBtn": {
      width: "74px",
      height: "74px",
      "&.MuiButton-root": {
        padding: "24px",
      },
    },
  },

  //
  // PRIMARY
  //
  btnPrimary: {
    "&.MuiButton-root": {
      backgroundColor: "#FF0032",
      "&:hover": {
        backgroundColor: "rgba(212, 6, 16, 1)",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "rgba(212, 6, 16, 1)",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "rgba(212, 6, 16, 1)",
        boxShadow: "none",
      },
    },
  },
  labelPrimary: {
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },

  //
  // SECONDARY
  //
  btnSecondary: {
    "&.MuiButton-root": {
      backgroundColor: "#F2F3F7",
      "&:hover": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
    },
    "&$iconBtn": {
      border: `1px solid ${theme.mtsColor.background.stroke.lightMode}`,
      "& .MuiSvgIcon-root": {
        fill: theme.mtsColor.text.secondaryLink.lightMode,
      },
    },
    "&$iconRoundBtn": {
      border: `1px solid ${theme.mtsColor.text.secondaryLink.lightMode}`,
      "& .MuiSvgIcon-root": {
        fill: theme.mtsColor.text.secondaryLink.lightMode,
      },
    },
  },
  labelSecondary: {
    "&.MuiTypography-root": {
      color: "#1D2023",
    },
  },
  //
  // SECONDARY INVERTED
  //
  btnSecondaryInverted: {
    "&.MuiButton-root": {
      backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#EEF0F3",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "#EEF0F3",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "#EEF0F3",
        boxShadow: "none",
      },
    },
  },
  labelSecondaryInverted: {
    "&.MuiTypography-root": {
      color: "#1D2023",
    },
  },
  //
  // GHOST
  //
  btnGhost: {
    "&.MuiButton-root": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
    },
  },
  labelGhost: {
    "&.MuiTypography-root": {
      color: "#1D2023",
    },
  },
  //
  // NEGATIVE
  //
  btnNegative: {
    "&.MuiButton-root": {
      backgroundColor: "#F2F3F7",
      "&:hover": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
    },
  },
  labelNegative: {
    "&.MuiTypography-root": {
      color: "#EB4A13",
    },
  },
  //
  // NEGATIVE INVERTED
  //
  btnNegativeInverted: {
    "&.MuiButton-root": {
      backgroundColor: "#FFFFFF",
      "&:hover": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "rgba(226, 229, 236, 1)",
        boxShadow: "none",
      },
    },
  },
  labelNegativeInverted: {
    "&.MuiTypography-root": {
      color: "#EB4A13",
    },
  },
  //
  // OUTLINE
  //
  btnOutline: {
    "&.MuiButton-root": {
      backgroundColor: "#FFFFFF",
      border: "2px solid rgba(238, 240, 243, 1)",
      "&:hover": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
      "&:active": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
      "&:focus": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
    },
  },
  labelOutline: {
    "&.MuiTypography-root": {
      color: "#1D2023",
    },
  },
  //
  // OUTLINE NEGATIVE
  //
  btnOutlineNegative: {
    "&.MuiButton-root": {
      backgroundColor: "#FFFFFF",
      border: "2px solid rgba(238, 240, 243, 1)",
      "&:hover": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
      "&:active": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
      "&:focus": {
        backgroundColor: "rgba(238, 240, 243, 1)",
      },
    },
  },
  labelOutlineNegative: {
    "&.MuiTypography-root": {
      color: "#EB4A13",
    },
  },

  //
  // OUTLINE LIGHT
  //
  btnOutlineLight: {
    "&.MuiButton-root": {
      backgroundColor: "transparent",
      border: "1px solid #FAFAFA",
      "&:hover": {
        border: `1px solid ${theme.mtsColor.grey.greyLight}`,
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
      "&:active": {
        border: `1px solid ${theme.mtsColor.grey.greyLight}`,
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
      "&:focus": {
        border: `1px solid ${theme.mtsColor.grey.greyLight}`,
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
    },
    "&:hover .MuiTypography-root": {
      color: theme.mtsColor.text.primary.lightMode,
    },
  },
  labelOutlineLight: {
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },

  //
  // OUTLINE DARK
  //
  btnOutlineDark: {
    "&.MuiButton-root": {
      backgroundColor: "transparent",
      border: "1px solid #000000",
      "&:hover": {
        border: "1px solid #000000",
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
      "&:active": {
        border: "1px solid #000000",
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
      "&:focus": {
        border: "1px solid #000000",
        backgroundColor: theme.mtsColor.grey.greyLight,
      },
    },
    "&:hover .MuiTypography-root": {
      color: theme.mtsColor.text.primary.lightMode,
    },
  },
  labelOutlineDark: {
    "&.MuiTypography-root": {
      color: "#000000",
    },
  },

  //
  // DARK BLUE
  //
  btnDarkBlue: {
    "&.MuiButton-root": {
      backgroundColor: "#122840",
      border: "1px solid #06182D",
      "&:hover": {
        backgroundColor: "#06182D",
      },
      "&:active": {
        backgroundColor: "#06182D",
      },
      "&:focus": {
        backgroundColor: "#06182D",
      },
    },
  },
  labelDarkBlue: {
    "&.MuiTypography-root": {
      color: "#FFFFFF",
    },
  },

  //
  // DISABLED
  //
  btnDisabled: {
    "&.MuiButton-root": {
      backgroundColor: "rgba(188, 195, 208, 0.5)",
      "&:hover": {
        backgroundColor: "rgba(188, 195, 208, 0.5)",
      },
      "&:active": {
        backgroundColor: "rgba(188, 195, 208, 0.5)",
      },
      "&:focus": {
        backgroundColor: "rgba(188, 195, 208, 0.5)",
      },
    },
  },
  labelDisabled: {
    "&.MuiTypography-root": {
      color: "#969FA8",
    },
  },
  spinnerBlock: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  spinner: {
    animationName: "$loader",
    pointerEvents: "none",
    animation: "App-logo-spin infinite 1s linear",
  },
  "@keyframes loader": {
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  },
  iconButtons: {
    marginRight: "4px",
    display: "flex",
    justifyContent: "center",
  },
  iconWhite: {
    "& .MuiSvgIcon-root": {
      fill: theme.mtsColor.text.primary.darkMode,
    },
  },
  iconBlack: {
    "& .MuiSvgIcon-root": {
      fill: theme.mtsColor.text.primary.lightMode,
    },
  },
  iconGrey: {
    "& .MuiSvgIcon-root": {
      fill: theme.mtsColor.text.secondary.lightMode,
    },
  },
  iconRed: {
    "& .MuiSvgIcon-root": {
      fill: theme.mtsColor.red,
    },
  },
  iconHidden: {
    visibility: "hidden",
  },
  iconBtn: {
    "&.MuiButton-root": {
      minWidth: "inherit",
      padding: "4px",
    },
  },
  iconRoundBtn: {
    "&.MuiButton-root": {
      minWidth: "inherit",
      padding: "4px",
      borderRadius: "50%",
    },
  },
}));

export default useStyles;
