import { format } from "date-fns";
import theme from "theme";
import rgba from "polished/lib/color/rgba";

import Icon from "components/icons";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";
import { BaseLink } from "components/link/link";
import { IAskue } from "interface/askue";
import { ROUTE_ASKUE_DEVICES } from "stream-constants/route-constants";
import { SCard, SIconCard, SMetersGrid, SNumber, SVal, SWrap } from "./styles";

interface IProp {
  data: IAskue[];
}

export const MetersGrid: React.FC<IProp> = ({ data }) => {
  return (
    <>
      <SMetersGrid>
        {data.slice(0, 4).map((item) => (
          <MetersCard data={item} key={item.sn} />
        ))}
      </SMetersGrid>
      <Spacer value="24px" />
      {data.length > 4 ? (
        <SWrap>
          <BaseLink href={`/${ROUTE_ASKUE_DEVICES}`} size="S" text="Все устройства" />
        </SWrap>
      ) : null}
    </>
  );
};

const MetersCard: React.FC<{ data: any }> = ({ data }) => {
  return (
    <SCard>
      <SNumber>
        <CusTypo variant="p4_medium" font="comp">
          Серийный номер: {data.sn}
        </CusTypo>
      </SNumber>
      <Spacer value="4px" />
      <div>
        <CusTypo
          variant="c1_regular"
          font="comp"
          styles={{ color: rgba(theme.mtsColor.text.primary.darkMode, 0.7) }}
        >
          Модель: {data.meter_name}
        </CusTypo>
      </div>
      <Spacer value="16px" />
      <SVal>
        <CusTypo variant="h2_medium" font="wide">
          {data.ActEpr}{" "}
        </CusTypo>
        <CusTypo
          variant="h4_medium"
          font="wide"
          styles={{ color: rgba(theme.mtsColor.text.primary.darkMode, 0.7) }}
        >
          кВт/ч
        </CusTypo>
      </SVal>
      <Spacer value="4px" />
      <div>
        <CusTypo
          variant="c2_regular"
          styles={{ color: rgba(theme.mtsColor.text.primary.darkMode, 0.7) }}
        >
          Дата передачи данных: {format(new Date(data.dt_verify ?? 0), "dd.MM.yyyy")}
        </CusTypo>
      </div>
      <SIconCard>
        <Icon.AskueCard sx={{ width: 146, height: 178 }} />
      </SIconCard>
    </SCard>
  );
};
