import { device } from "theme";
import styled from "@emotion/styled";

export const SWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SMetersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 16px;

  @media ${device.tabletPlus} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(auto, 1fr);
  }
`;

export const SCard = styled.div`
  position: relative;
  padding: 12px;
  border-radius: 8px;
  background-color: #122840;
  overflow: hidden;

  @media ${device.tabletPlus} {
    padding: 32px;
  }
`;

export const SNumber = styled.div`
  & .MuiTypography-root {
    color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
  }
`;
export const SVal = styled.div`
  & .MuiTypography-root {
    &:first-of-type {
      color: ${(props) => props.theme.mtsColor.text.primary.darkMode};
    }
  }
`;

export const SIconCard = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 146px;
  max-height: 178px;
  width: 100%;
`;
