import { useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { isEmpty } from "lodash";

import { WelcomePage } from "pages/welcomePage/welcomePage";
import { UserAgreement } from "pages/userAgreement/userAgreement";
import { Loader } from "components/loader/loader";
import { ContextSettings } from "../../context";
import {
  ROUTE_PERSONAL_AREA,
  ROUTE_USER_AGREEMENT,
  ROUTE_SECURITY,
} from "stream-constants/route-constants";
import { profile_mock } from "services/profile/mock-data";
import { IRoles } from "interface/common";
import { ProtectedRoute } from "./routes/route.component";
import { Security } from "pages/security/security";
import { LIST_ROLES } from "utils/roles";
import { isDevMode } from "services/urls";
import { refresh } from "services/auth";
import { fetchProfile } from "services/profile";
import {
  getAbonentAskueRoutes,
  getAllRoutes,
  getDemoAskueRoutes,
  getDemoTestRoutes,
} from "./routes/utils";
import { useGetAbonentStatus } from "hooks/api/abonent";
import { EStatus } from "components/forms/abonent-connect-device/utils";
import { SERVICES } from "stream-constants/keys-and-constants";
import { SApp } from "./styles";

const getArrayRoles = (array: IRoles[] | undefined, callback: Function) => {
  const rolesArray = array?.map((item) => item.name) ?? [];
  callback(rolesArray);
};

function App() {
  const [isPending, setPending] = useState(true);
  const { isAuthorized, setIsAuthorized, profile, roles, setRoles, mock_mode, setProfile } =
    useContext(ContextSettings);
  const abonentStatus = useGetAbonentStatus(isAuthorized && roles.includes(LIST_ROLES.ABONENT));

  const IS_MOCK_MODE = mock_mode === "on" && isDevMode();

  useEffect(() => {
    const checkAuth = async () => {
      setPending(true);
      try {
        const response = await refresh();
        if (response.ok) {
          try {
            const prof = await fetchProfile();
            if (prof.ok) {
              const res = await prof.json();
              setIsAuthorized(true);
              setProfile(res);
              setPending(false);
            } else {
              throw new Error("profile error");
            }
          } catch (err) {
            throw new Error(err);
          }
        } else {
          throw new Error("validating error");
        }
      } catch (error) {
        setIsAuthorized(false);
        setPending(false);
      }
    };

    if (IS_MOCK_MODE) {
      setIsAuthorized(true);
      setProfile(profile_mock);
      setPending(false);
    } else {
      checkAuth();
    }
  }, [setIsAuthorized, IS_MOCK_MODE, setProfile]);

  useEffect(() => {
    if (IS_MOCK_MODE) getArrayRoles(profile_mock.roles, setRoles);
    else {
      if (!isEmpty(profile?.roles)) getArrayRoles(profile?.roles, setRoles);
    }
  }, [profile, IS_MOCK_MODE, setRoles]);

  const elems =
    !isEmpty(roles) && profile?.services_ch != null && getAllRoutes(roles, profile?.services_ch);

  const isDemoAskue = profile?.services_ch.includes(SERVICES.ASKUE);

  return (
    <SApp>
      {isPending && (!elems || typeof elems !== "object") ? (
        <Loader isFullPage />
      ) : (
        <Routes>
          <Route element={<ProtectedRoute isHideHeader isHideSidebar isAuthorized />}>
            <Route index element={<WelcomePage />} />
            <Route path={`/${ROUTE_USER_AGREEMENT}`} element={<UserAgreement />} />
          </Route>

          {roles.includes(LIST_ROLES.ABONENT) ? (
            <Route
              element={
                <ProtectedRoute
                  isHideSidebar={abonentStatus.data?.status !== EStatus.accept}
                  isAuthorized={isAuthorized}
                />
              }
            >
              {getAbonentAskueRoutes()}
            </Route>
          ) : roles.includes(LIST_ROLES.DEMO_TEST) ? (
            <Route
              element={<ProtectedRoute isHideSidebar={!isDemoAskue} isAuthorized={isAuthorized} />}
            >
              {isDemoAskue ? getDemoAskueRoutes() : getDemoTestRoutes()}
            </Route>
          ) : (
            <Route element={<ProtectedRoute isAuthorized={isAuthorized} />}>
              {elems}
              <Route
                path={`/${ROUTE_PERSONAL_AREA}/${ROUTE_USER_AGREEMENT}`}
                element={<UserAgreement />}
              />
              <Route path={`/${ROUTE_PERSONAL_AREA}/${ROUTE_SECURITY}`} element={<Security />} />
            </Route>
          )}

          <Route path="*" element={<WelcomePage />} />
        </Routes>
      )}
    </SApp>
  );
}

export default App;
