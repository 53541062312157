import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import styled from "@emotion/styled";

import { p4_styles } from "theme";
import { CusTypo } from "components/cusTypo/custom-typography";

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  root: {
    minWidth: "140px",
    "& .MuiAutocomplete-endAdornment": {
      right: "12px !important",
      top: "50%",
      transform: "translateY(-50%)",
    },

    "& input::placeholder": {
      color: theme.mtsColor.text.tertiary.lightMode,
    },

    "& .MuiOutlinedInput-root": {
      ...p4_styles,
      color: theme.mtsColor.text.primary.lightMode,
      minHeight: "44px",
      padding: "0px!important",
      borderRadius: "8px",
      backgroundColor: "#fff",

      "&:focus-visible": {
        outline: "none !important",
        border: "none !important",
      },

      "& .MuiOutlinedInput-notchedOutline": {
        outlineWidth: "1px !important",
      },
      "& fieldset": {
        border: "1px solid",
        borderWidth: "1px !important",
        borderColor: theme.mtsColor.background.stroke.lightMode,
        borderRadius: "8px",
        transition: "0.2s all ease-in-out",
        outline: "none !important",
      },
      "&:focus-within fieldset": {
        borderColor: theme.mtsColor.text.primaryLink.lightMode,
      },
      "&:hover &:not(:focus) fieldset": {
        borderColor: theme.mtsColor.controls.secondaryActive.lightMode,
      },
      "&.Mui-disabled": {
        backgroundColor: theme.mtsColor.background.secondary.lightMode,
      },
      "&.Mui-disabled fieldset": {
        borderColor: theme.mtsColor.background.stroke.lightMode,
      },
    },

    "& .MuiAutocomplete-tag": {
      borderRadius: "8px",
      height: "28px",
      padding: "3px",
      margin: "8px -5px 8px 12px!important",
    },

    "& .MuiChip-deleteIcon": {
      margin: 0,
    },

    "& .MuiChip-label": {
      fontSize: 14,
    },

    "& .MuiAutocomplete-input": {
      padding: "0 35px 0 12px!important",
      height: "28px",
    },

    "& .MuiAutocomplete-clearIndicator": {
      display: "none!important",
    },

    "& .MuiButtonBase-root:hover": {
      background: "transparent",
    },
  },
  rootError: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.mtsColor.accent.negative.lightMode,
      },
      "&:hover fieldset": {
        borderColor: theme.mtsColor.accent.negative.lightMode,
      },
    },
  },
  fieldSizeS: {
    "& .MuiOutlinedInput-root": {
      minHeight: "32px",
    },
  },
  fieldSizeM: {
    "& .MuiOutlinedInput-root": {
      minHeight: "44px",
      fontSize: "1.21rem",
    },
  },
  fieldSizeL: {
    "& .MuiOutlinedInput-root": {
      minHeight: "52px",
      fontSize: "1.21rem",
    },
  },
  noOption: {
    "&.MuiAutocomplete-noOptions": {
      display: "flex",
      justifyContent: "center",
      padding: "32px 12px",
    },
  },
  noOptionText: {
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.tertiary.lightMode,
    },
  },
  icon: {
    color: theme.mtsColor.icons.secondary.lightMode,
    fontSize: 24,
  },
  listBox: {
    "&.MuiAutocomplete-listbox": {
      padding: "6px 0",
    },
  },
  paper: {
    borderRadius: "8px",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.12), 0px 12px 20px rgba(0, 0, 0, 0.14) !important",
    margin: "4px 0 4px 0",
  },
  option: {
    display: "flex",
    alignItems: "center",
    padding: "0 15px",
    "&:hover": {
      backgroundColor: "rgba(188, 195, 208, 0.25)",
    },
  },
  optionSizeS: {
    minHeight: "32px",
  },
  optionSizeM: {
    minHeight: "44px",
  },
  optionSizeL: {
    minHeight: "52px",
  },
  optionText: {
    display: "flex",
    alignItems: "center",
    "&.MuiTypography-root": {
      color: theme.mtsColor.text.primary.lightMode,
    },
  },
  checkBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 12,
  },
  tooltip: {
    "& li": {
      position: "relative",
      paddingLeft: "10px",
    },
    "& li:before": {
      content: "''",
      position: "absolute",
      top: "6px",
      left: 0,
      height: "4px",
      width: "4px",
      backgroundColor: theme.mtsColor.background.primaryElevated.lightMode,
      borderRadius: "5px",
    },
  },
  optionIcon: {
    "& svg": {
      width: 16,
      height: 16,
      margin: "0 0 0 8px",
    },
  },
}));

export default useStyles;

export const SSelect = styled.div`
  display: flex;
  align-items: center;
  & .MuiAutocomplete-root {
    flex: 1;
  }
`;

export const SDelIcon = styled.div`
  padding: 8px 4px;
  cursor: pointer;
`;

export const SNotifyText = styled(CusTypo)`
  display: block;
  text-align: left;
  color: ${(prop) => prop.theme.mtsColor.text.secondary.lightMode};
`;
