import { Navigate, Route } from "react-router-dom";
import { intersection, uniq, isEmpty } from "lodash";

import {
  ROUTE_ANOMALIES,
  ROUTE_BILLING_PAGE,
  ROUTE_DEMO,
  ROUTE_DEMO_MANAGEMENT,
  ROUTE_DEVICE_ITEM,
  ROUTE_DEVICE_LIST_PAGE,
  ROUTE_EVENTS,
  ROUTE_MANAGEMENT,
  ROUTE_PERSONAL_AREA,
  ROUTE_RESEARCH_PAGE,
  ROUTE_TENANT_PAGE,
  ROUTE_DEVICE_UPDATE,
  ROUTE_USERS,
  ROUTE_ASKUE_PAGE,
  ROUTE_TENANT_ITEM,
  ROUTE_USERS_ITEM,
  ROUTE_ASKUE_DEVICES,
  ROUTE_ASKUE_MANAGEMENT,
  ROUTE_ASKUE_EVENTS,
  ROUTE_ANALYTICS_PAGE,
  ROUTE_DEPARTURES,
  ROUTE_DEPARTURES_MAP,
  ROUTE_MINING,
  ROUTE_MINING_MAP,
  ROUTE_MINING_ARCHIVE,
  ROUTE_SHOP_MANAGEMENT,
  ROUTE_HANDBOOK,
  ROUTE_ASKUE_INSTRUCTIONS,
  ROUTE_ANAL_STAT,
  ROUTE_RESEARCH_GEO_PAGE,
  ROUTE_TARIFF,
  ROUTE_ABONENT_MANAGEMENT,
  ROUTE_ABONENT_ITEM,
} from "stream-constants/route-constants";
import { DeviceListPage } from "pages/deviceList/deviceListPage";
import { ManagementBilling } from "pages/managementBilling/managementBilling";
import { ManagementUsers } from "pages/managementUsers/managementUsers";
import { AnomaliesPage } from "pages/anomalies/anomalies";
import { ManagementTenant } from "pages/managementTenant/managementTenant";
import { AnalyticsResearch } from "pages/analytics/analyticsResearchPage";
import { ManagementAskue } from "pages/managementAskue/managementAskue";
import { ManagementDemo } from "pages/managementDemo/managementDemo";
import { DeviceItemPage } from "pages/deviceList/deviceItemPage";
import { MobDeviceUpdatePage } from "pages/deviceList/mobDeviceUpdatePage";
import { ManagementEvents } from "pages/managementEvents/managementEventsPage";
import { DemoAnalyticsPage } from "pages/demoAnalytics/demoAnalyticsPage";
import { AskuePage } from "pages/askue/askuePage";
import { AskueListPage } from "pages/askue/askueListPage";
import { AskueEvents } from "pages/askue/askueEvents";
import { TenantItemPage } from "pages/managementTenant/tenantItemPage";
import { UserItemPage } from "pages/managementUsers/userItemPage";
import { MapPage } from "pages/mapPage/map";
import { LIST_ROLES } from "utils/roles";
import { MiningPage } from "pages/mining/miningPage";
import { MiningArchive } from "pages/miningArchive/miningArchivePage";
import { SERVICES } from "stream-constants/keys-and-constants";
import { ManagementShop } from "pages/managementShop/managementShop";
import { AnalyticsHandbook } from "pages/analytics/analyticsHandbookPage";
import { MainPage } from "pages/main/mainPage";
import { AskueInstruction } from "pages/askue/askueInstruction";
import { AnalyticsStat } from "pages/analytics/analyticsStatPage";
import { AnalyticsGeo } from "pages/analytics/analyticsGeoPage";
import { ManagementTariff } from "pages/managementTariff/managementTariffPage";
import { DeparturesPage } from "pages/departures/departuresPage";
import { AbonentAskue } from "pages/askue/askueAbonentPage";
import { AskueAbonentDeviceList } from "pages/askue/askueAbonentDeviceList";
import { AskueAbonentEvents } from "pages/askue/askueAbonentEvents";
import { ManagementAbonent } from "pages/managementAbonent/managementAbonent";
import { AbonentItemPage } from "pages/managementAbonent/abonentItemPage";

// https://confluence.mts.ru/pages/viewpage.action?pageId=777523198
/* Здесь получилось не совсем по гайду конфлю. В связи с выбранной структурой, добавил Аналитику в разрешенные при любой услуге,
но routesByRole является первоисточником доступности раздела
*/
const routeByService = {
  [SERVICES.LOSSES]: [
    ROUTE_DEVICE_LIST_PAGE,
    ROUTE_DEVICE_ITEM,
    ROUTE_ANOMALIES,
    ROUTE_DEVICE_UPDATE,
    ROUTE_DEPARTURES,
    ROUTE_DEPARTURES_MAP,
    ROUTE_TENANT_PAGE,
    ROUTE_TENANT_ITEM,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_BILLING_PAGE,
    ROUTE_EVENTS,
    ROUTE_ASKUE_MANAGEMENT,
    ROUTE_DEMO_MANAGEMENT,
    ROUTE_RESEARCH_PAGE,
    ROUTE_RESEARCH_GEO_PAGE,
    ROUTE_SHOP_MANAGEMENT,
    ROUTE_HANDBOOK,
    ROUTE_ANAL_STAT,
    ROUTE_TARIFF,
  ],
  [SERVICES.ASKUE]: [
    ROUTE_ASKUE_PAGE,
    ROUTE_ASKUE_DEVICES,
    ROUTE_ASKUE_EVENTS,
    ROUTE_ASKUE_INSTRUCTIONS,
    ROUTE_TENANT_PAGE,
    ROUTE_TENANT_ITEM,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_BILLING_PAGE,
    ROUTE_EVENTS,
    ROUTE_ASKUE_MANAGEMENT,
    ROUTE_DEMO_MANAGEMENT,
    ROUTE_RESEARCH_PAGE,
    ROUTE_RESEARCH_GEO_PAGE,
    ROUTE_HANDBOOK,
    ROUTE_SHOP_MANAGEMENT,
    ROUTE_ANAL_STAT,
    ROUTE_TARIFF,
    ROUTE_ABONENT_MANAGEMENT,
    ROUTE_ABONENT_ITEM,
  ],
  [SERVICES.MINING]: [
    ROUTE_MINING,
    ROUTE_MINING_MAP,
    ROUTE_MINING_ARCHIVE,
    ROUTE_TENANT_PAGE,
    ROUTE_TENANT_ITEM,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_BILLING_PAGE,
    ROUTE_EVENTS,
    ROUTE_ASKUE_MANAGEMENT,
    ROUTE_DEMO_MANAGEMENT,
    ROUTE_RESEARCH_PAGE,
    ROUTE_RESEARCH_GEO_PAGE,
    ROUTE_HANDBOOK,
    ROUTE_SHOP_MANAGEMENT,
    ROUTE_ANAL_STAT,
    ROUTE_TARIFF,
  ],
};

const componentByRoute = {
  [ROUTE_DEVICE_LIST_PAGE]: <DeviceListPage />,
  [ROUTE_DEVICE_ITEM]: <DeviceItemPage />,
  [ROUTE_ANOMALIES]: <AnomaliesPage />,
  [ROUTE_DEVICE_UPDATE]: <MobDeviceUpdatePage />,
  [ROUTE_BILLING_PAGE]: <ManagementBilling />,
  [ROUTE_USERS]: <ManagementUsers />,
  [ROUTE_USERS_ITEM]: <UserItemPage />,
  [ROUTE_EVENTS]: <ManagementEvents />,
  [ROUTE_ASKUE_PAGE]: <AskuePage />,
  [ROUTE_ASKUE_DEVICES]: <AskueListPage />,
  [ROUTE_ASKUE_EVENTS]: <AskueEvents />,
  [ROUTE_DEPARTURES]: <DeparturesPage />,
  [ROUTE_DEPARTURES_MAP]: <MapPage />,
  [ROUTE_MINING]: <MiningPage />,
  [ROUTE_MINING_MAP]: <MapPage />,
  [ROUTE_MINING_ARCHIVE]: <MiningArchive />,
  [ROUTE_TENANT_PAGE]: <ManagementTenant />,
  [ROUTE_TENANT_ITEM]: <TenantItemPage />,
  [ROUTE_ASKUE_MANAGEMENT]: <ManagementAskue />,
  [ROUTE_DEMO_MANAGEMENT]: <ManagementDemo />,
  [ROUTE_RESEARCH_PAGE]: <AnalyticsResearch />,
  [ROUTE_RESEARCH_GEO_PAGE]: <AnalyticsGeo />,
  [ROUTE_SHOP_MANAGEMENT]: <ManagementShop />,
  [ROUTE_HANDBOOK]: <AnalyticsHandbook />,
  [ROUTE_ANAL_STAT]: <AnalyticsStat />,
  [ROUTE_TARIFF]: <ManagementTariff />,
  [ROUTE_ABONENT_MANAGEMENT]: <ManagementAbonent />,
  [ROUTE_ABONENT_ITEM]: <AbonentItemPage />,
};

const routesByRole = {
  [LIST_ROLES.OWNER]: [
    ROUTE_DEVICE_LIST_PAGE,
    ROUTE_DEVICE_ITEM,
    ROUTE_ANOMALIES,
    ROUTE_DEVICE_UPDATE,
    ROUTE_BILLING_PAGE,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_EVENTS,
    ROUTE_ASKUE_PAGE,
    ROUTE_ASKUE_DEVICES,
    ROUTE_ASKUE_EVENTS,
    ROUTE_DEPARTURES,
    ROUTE_DEPARTURES_MAP,
    ROUTE_MINING,
    ROUTE_MINING_MAP,
    ROUTE_MINING_ARCHIVE,
    ROUTE_ABONENT_MANAGEMENT,
    ROUTE_ABONENT_ITEM,
  ],
  [LIST_ROLES.ADMIN]: [
    ROUTE_BILLING_PAGE,
    ROUTE_TENANT_PAGE,
    ROUTE_TENANT_ITEM,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_ASKUE_MANAGEMENT,
    ROUTE_DEMO_MANAGEMENT,
    ROUTE_EVENTS,
    ROUTE_ASKUE_PAGE,
    ROUTE_ASKUE_DEVICES,
    ROUTE_ASKUE_EVENTS,
    ROUTE_SHOP_MANAGEMENT,
    ROUTE_TARIFF,
  ],
  [LIST_ROLES.ANALYTIC]: [
    ROUTE_DEVICE_LIST_PAGE,
    ROUTE_DEVICE_ITEM,
    ROUTE_DEVICE_UPDATE,
    ROUTE_ANOMALIES,
    ROUTE_BILLING_PAGE,
    ROUTE_ASKUE_PAGE,
    ROUTE_ASKUE_DEVICES,
    ROUTE_ASKUE_EVENTS,
    ROUTE_DEPARTURES,
    ROUTE_DEPARTURES_MAP,
    ROUTE_MINING,
    ROUTE_MINING_MAP,
    ROUTE_MINING_ARCHIVE,
  ],
  [LIST_ROLES.ANALYST_SYSTEM]: [
    ROUTE_ANOMALIES,
    ROUTE_DEVICE_LIST_PAGE,
    ROUTE_DEVICE_ITEM,
    ROUTE_DEVICE_UPDATE,
    ROUTE_RESEARCH_PAGE,
    ROUTE_RESEARCH_GEO_PAGE,
    ROUTE_HANDBOOK,
    ROUTE_ANAL_STAT,
    ROUTE_TENANT_PAGE,
    ROUTE_TENANT_ITEM,
    ROUTE_USERS,
    ROUTE_USERS_ITEM,
    ROUTE_BILLING_PAGE,
    ROUTE_ASKUE_PAGE,
    ROUTE_ASKUE_DEVICES,
    ROUTE_ASKUE_EVENTS,
    ROUTE_DEPARTURES,
    ROUTE_DEPARTURES_MAP,
    ROUTE_MINING,
    ROUTE_MINING_MAP,
    ROUTE_MINING_ARCHIVE,
  ],
  [LIST_ROLES.ENGINEER]: [
    ROUTE_ANOMALIES,
    ROUTE_DEVICE_LIST_PAGE,
    ROUTE_DEVICE_ITEM,
    ROUTE_DEVICE_UPDATE,
  ],
  [LIST_ROLES.ABONENT]: [ROUTE_ASKUE_PAGE, ROUTE_ASKUE_DEVICES, ROUTE_ASKUE_EVENTS],
};

const calcRoutesSet = (role: string, services: string[]) => {
  const target = routesByRole[role];
  let start: string[] = [];
  services.forEach((serv) => (start = start.concat(intersection(target, routeByService[serv]))));
  return uniq(start);
};

type IObj = {
  [key in SERVICES]: string;
};

const redirByRole = {
  [LIST_ROLES.OWNER]: {
    [ROUTE_MANAGEMENT]: ROUTE_USERS,
  },
  [LIST_ROLES.ADMIN]: {
    [ROUTE_MANAGEMENT]: ROUTE_TENANT_PAGE,
  },
  [LIST_ROLES.ANALYTIC]: {
    [ROUTE_MANAGEMENT]: ROUTE_BILLING_PAGE,
  },
  [LIST_ROLES.ANALYST_SYSTEM]: {
    [ROUTE_MANAGEMENT]: ROUTE_TENANT_PAGE,
    [ROUTE_ANALYTICS_PAGE]: ROUTE_RESEARCH_PAGE,
  },
  [LIST_ROLES.ENGINEER]: {},
};

const calcRedirects = (role: LIST_ROLES, services: string[]) => {
  const root = redirByRole[role] ?? {};
  return Object.entries(root)
    .map(([key, destination]) => {
      if ((destination as IObj).constructor.name === "Object") {
        return (destination as IObj)[services[0]] != null
          ? [key, (destination as IObj)[services[0]]]
          : [];
      } else {
        return [key, destination];
      }
    })
    .filter((a) => !isEmpty(a));
};

export const getAllRoutes = (roles, services) => {
  const redirects = roles.map((r) => calcRedirects(r, services)).flat();
  const routes: string[] = uniq(
    roles.map((ro) => calcRoutesSet(ro, services)).reduce((tot, cur) => [...tot, ...cur]),
  );

  return (
    <>
      {redirects.map(([orig, dest]) => {
        return (
          <Route key={`${orig}_${dest}`} path={`/${orig}`} element={<Navigate to={`/${dest}`} />} />
        );
      })}
      <Route path={`/${ROUTE_PERSONAL_AREA}`} element={<MainPage />} />
      {routes.map((route) => (
        <Route key={`path_${route}`} path={route} element={componentByRoute[route]} />
      ))}
    </>
  );
};

export const getDemoTestRoutes = () => (
  <>
    <Route path={`/${ROUTE_PERSONAL_AREA}`} element={<Navigate replace to={`/${ROUTE_DEMO}`} />} />
    <Route path={`/${ROUTE_DEMO}`} element={<DemoAnalyticsPage />} />
  </>
);

export const getDemoAskueRoutes = () => (
  <>
    <Route
      path={`/${ROUTE_PERSONAL_AREA}`}
      element={<Navigate replace to={`/${ROUTE_ASKUE_PAGE}`} />}
    />
    <Route path={`/${ROUTE_ASKUE_PAGE}`} element={<AskuePage />} />
    <Route path={`/${ROUTE_ASKUE_DEVICES}`} element={<AskueListPage isDemo />} />
    <Route path={`/${ROUTE_ASKUE_INSTRUCTIONS}`} element={<AskueInstruction />} />
    <Route path={`/${ROUTE_ASKUE_EVENTS}`} element={<AskueEvents />} />
  </>
);

export const getAbonentAskueRoutes = () => (
  <>
    <Route
      path={`/${ROUTE_PERSONAL_AREA}`}
      element={<Navigate replace to={`/${ROUTE_ASKUE_PAGE}`} />}
    />
    <Route path={`/${ROUTE_ASKUE_PAGE}`} element={<AbonentAskue />} />
    <Route path={`/${ROUTE_ASKUE_DEVICES}`} element={<AskueAbonentDeviceList />} />
    <Route path={`/${ROUTE_ASKUE_EVENTS}`} element={<AskueAbonentEvents />} />
  </>
);
