import * as yup from "yup";

export function getSchema(renderDevice: boolean) {
  return yup.object().shape({
    discreteness: yup.string().nullable(),
    period: yup.date().nullable(),
    ...(renderDevice && {
      devices: yup.array().required("Поле не заполнено").min(1, "Минимум 1 устройство"),
    }),
  });
}
