import { FC, Fragment } from "react";

import { useMutationDeleteAbonent } from "hooks/api/management";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { SBtns, SSubtitle, STop } from "./styles";

type IProps = {
  id: number;
  onCancel: () => void;
};
export const FormDeleteAbonent: FC<IProps> = ({ id, onCancel }) => {
  const mutation = useMutationDeleteAbonent();

  return (
    <>
      <STop>
        <CusTypo variant="p1_medium" font="comp">
          Удалить абонента
        </CusTypo>
        <Spacer value="8px" />
        <SSubtitle variant="p4_regular">
          Абоненту станет недоступен личный кабинет. <br /> Для восстановления доступа ему придется
          заново зарегистрироваться.
        </SSubtitle>
        <Spacer value="24px" />
      </STop>
      <SBtns>
        <MTSButton
          size="M"
          variant="primary"
          type="submit"
          onClick={() => {
            mutation.mutate(id);
            onCancel();
          }}
        >
          Удалить абонента
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={onCancel}>
          Отмена
        </MTSButton>
      </SBtns>
    </>
  );
};
