import { IDataSidebar, ISidebarSubitem } from "interface/common";
import {
  ROUTE_ANOMALIES,
  ROUTE_DEVICE_LIST_PAGE,
  ROUTE_PERSONAL_AREA,
  ROUTE_MANAGEMENT,
  ROUTE_TENANT_PAGE,
  ROUTE_USERS,
  ROUTE_BILLING_PAGE,
  ROUTE_RESEARCH_PAGE,
  ROUTE_ANALYTICS,
  ROUTE_DEMO_MANAGEMENT,
  ROUTE_EVENTS,
  ROUTE_ASKUE_PAGE,
  ROUTE_ASKUE_DEVICES,
  ROUTE_ASKUE_MANAGEMENT,
  ROUTE_ASKUE_EVENTS,
  ROUTE_DEPARTURES,
  ROUTE_DEPARTURES_MAP,
  ROUTE_MINING,
  ROUTE_MINING_MAP,
  ROUTE_MINING_ARCHIVE,
  ROUTE_SHOP_MANAGEMENT,
  ROUTE_HANDBOOK,
  ROUTE_ASKUE_INSTRUCTIONS,
  ROUTE_ANAL_STAT,
  ROUTE_TARIFF,
  ROUTE_ABONENT_MANAGEMENT,
} from "stream-constants/route-constants";
import { SERVICES } from "stream-constants/keys-and-constants";
import { LIST_ROLES } from "utils/roles";

const checkIntersection = (firstArr: string[], secondArr: string[]) => {
  return firstArr.some((firstRole) => secondArr.some((secondRole) => secondRole === firstRole));
};

const getFilteredSidebar = (array: IDataSidebar[], roles: LIST_ROLES[], services: string[]) => {
  const newArray: IDataSidebar[] = [];
  array.forEach((item: IDataSidebar) => {
    const newArraySubitem: ISidebarSubitem[] | undefined = [];

    item.subitem?.forEach((elem: ISidebarSubitem) => {
      if (checkIntersection(elem.forRoles, roles)) newArraySubitem.push(elem);
    });

    const fullItem = {
      ...item,
      subitem: newArraySubitem.length > 0 ? newArraySubitem : undefined,
    };

    if (checkIntersection(item.forRoles, roles)) {
      if (item.forServices) {
        if (checkIntersection(services, item.forServices)) newArray.push(fullItem);
      } else {
        newArray.push(fullItem);
      }
    }
  });

  return newArray;
};

const dataSidebarByRoles: IDataSidebar[] = [
  {
    id: "main",
    title: "Главная",
    link: `/${ROUTE_PERSONAL_AREA}`,
    forRoles: [
      LIST_ROLES.OWNER,
      LIST_ROLES.ANALYTIC,
      LIST_ROLES.ANALYST_SYSTEM,
      LIST_ROLES.ENGINEER,
      LIST_ROLES.ADMIN,
    ],
    forServices: [SERVICES.LOSSES, SERVICES.ASKUE, SERVICES.MINING],
  },
  {
    id: "askue",
    title: "АСКУЭ",
    link: `${ROUTE_ASKUE_PAGE}`,
    subitem: [
      {
        name: "Устройства",
        link: `/${ROUTE_ASKUE_DEVICES}`,
        forRoles: [
          LIST_ROLES.OWNER,
          LIST_ROLES.ANALYTIC,
          LIST_ROLES.ANALYST_SYSTEM,
          LIST_ROLES.ADMIN,
          LIST_ROLES.DEMO_TEST,
          LIST_ROLES.ABONENT,
        ],
      },
      {
        name: "Журнал событий",
        link: `/${ROUTE_ASKUE_EVENTS}`,
        forRoles: [
          LIST_ROLES.OWNER,
          LIST_ROLES.ANALYTIC,
          LIST_ROLES.ANALYST_SYSTEM,
          LIST_ROLES.ADMIN,
          LIST_ROLES.DEMO_TEST,
          LIST_ROLES.ABONENT,
        ],
      },
      {
        name: "Инструкции",
        link: `/${ROUTE_ASKUE_INSTRUCTIONS}`,
        forRoles: [LIST_ROLES.DEMO_TEST],
      },
    ],
    forRoles: [
      LIST_ROLES.OWNER,
      LIST_ROLES.ANALYTIC,
      LIST_ROLES.ANALYST_SYSTEM,
      LIST_ROLES.ADMIN,
      LIST_ROLES.DEMO_TEST,
      LIST_ROLES.ABONENT,
    ],
    forServices: [SERVICES.ASKUE],
  },
  {
    id: "anomalies",
    title: "Аномалии",
    link: `/${ROUTE_ANOMALIES}`,
    subitem: [
      {
        name: "Устройства",
        link: `/${ROUTE_DEVICE_LIST_PAGE}`,
        forRoles: [
          LIST_ROLES.OWNER,
          LIST_ROLES.ANALYTIC,
          LIST_ROLES.ANALYST_SYSTEM,
          LIST_ROLES.ENGINEER,
        ],
      },
    ],
    forRoles: [
      LIST_ROLES.OWNER,
      LIST_ROLES.ANALYTIC,
      LIST_ROLES.ANALYST_SYSTEM,
      LIST_ROLES.ENGINEER,
    ],
    forServices: [SERVICES.LOSSES],
  },
  {
    id: "analytics",
    title: "Аналитика",
    link: `${ROUTE_PERSONAL_AREA}/${ROUTE_ANALYTICS}`,
    subitem: [
      {
        name: "Приборы учета",
        link: `/${ROUTE_RESEARCH_PAGE}`,
        forRoles: [LIST_ROLES.ANALYST_SYSTEM],
      },
      {
        name: "Оценки",
        link: `/${ROUTE_HANDBOOK}`,
        forRoles: [LIST_ROLES.ANALYST_SYSTEM],
      },
      {
        name: "Статистика РЭС",
        link: `/${ROUTE_ANAL_STAT}`,
        forRoles: [LIST_ROLES.ANALYST_SYSTEM],
      },
    ],
    forRoles: [LIST_ROLES.ANALYST_SYSTEM],
  },
  {
    id: "departures",
    title: "Выезды",
    link: `/${ROUTE_DEPARTURES}`,
    subitem: [
      {
        name: "Карта",
        link: `/${ROUTE_DEPARTURES_MAP}`,
        forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
      },
    ],
    forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
    forServices: [SERVICES.LOSSES],
  },
  {
    id: "mining",
    title: "Майнеры",
    link: `/${ROUTE_MINING}`,
    subitem: [
      {
        name: "Карта",
        link: `/${ROUTE_MINING_MAP}`,
        forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
      },
      {
        name: "Архив",
        link: `/${ROUTE_MINING_ARCHIVE}`,
        forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
      },
    ],
    forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
    forServices: [SERVICES.MINING],
  },
  {
    id: "management",
    title: "Управление",
    link: `${ROUTE_MANAGEMENT}`,
    subitem: [
      {
        name: "Организации",
        link: `/${ROUTE_TENANT_PAGE}`,
        forRoles: [LIST_ROLES.ADMIN, LIST_ROLES.ANALYST_SYSTEM],
      },
      {
        name: "Пользователи",
        link: `/${ROUTE_USERS}`,
        forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ADMIN, LIST_ROLES.ANALYST_SYSTEM],
      },
      {
        name: "События",
        link: `/${ROUTE_EVENTS}`,
        forRoles: [LIST_ROLES.OWNER, LIST_ROLES.ADMIN],
      },
      {
        name: "Счета",
        link: `/${ROUTE_BILLING_PAGE}`,
        forRoles: [
          LIST_ROLES.ADMIN,
          LIST_ROLES.OWNER,
          LIST_ROLES.ANALYTIC,
          LIST_ROLES.ANALYST_SYSTEM,
        ],
      },
      {
        name: "Тарификация",
        link: `/${ROUTE_TARIFF}`,
        forRoles: [LIST_ROLES.ADMIN],
      },
      {
        name: "АСКУЭ",
        link: `/${ROUTE_ASKUE_MANAGEMENT}`,
        forRoles: [LIST_ROLES.ADMIN],
      },
      {
        name: "EnergyTool Shop",
        link: `/${ROUTE_SHOP_MANAGEMENT}`,
        forRoles: [LIST_ROLES.ADMIN],
      },
      {
        name: "Демо",
        link: `/${ROUTE_DEMO_MANAGEMENT}`,
        forRoles: [LIST_ROLES.ADMIN],
      },
      {
        name: "Абоненты",
        link: `/${ROUTE_ABONENT_MANAGEMENT}`,
        forRoles: [LIST_ROLES.OWNER],
      },
    ],
    forRoles: [LIST_ROLES.ADMIN, LIST_ROLES.OWNER, LIST_ROLES.ANALYTIC, LIST_ROLES.ANALYST_SYSTEM],
    forServices: [SERVICES.LOSSES, SERVICES.ASKUE, SERVICES.MINING],
  },
];

export const getSidebarList = (roles: LIST_ROLES[], services: string[]) =>
  getFilteredSidebar(dataSidebarByRoles, roles, services);
