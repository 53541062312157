import { IProfileStruct } from "interface/common";

export const profile_mock: IProfileStruct = {
  createdTime: "2022-05-10T10:00:00",
  email: "besk@mail.ru",
  id: 2,
  isActive: true,
  phone: "9105486650",
  current_tenant: [],
  tenant: {
    email: "test1@mts.ru",
    id: "dded5567-50b3-4399-8d2d-656442d147da",
    phone: null,
    title: "ОАО Компания",
  },
  services_ch: [
    "Поиск коммерческих потерь",
    "Облачная платформа учета",
    "Выявление майнинговых ферм",
  ],
  tenants: [
    {
      id: "7d4cc160-2772-11ec-9145-e180c6905313",
      title: "ООО БашкирЭнерго",
    },
    {
      id: "b1988760-0ecd-11eb-9918-e55f481af088",
      title: "ООО Иванофф Инвестиции",
    },
    {
      id: "b1988760-0ecd-11eb-9918-e55f481af088_202",
      title: "Иванов Иван Иванович",
    },
    {
      id: "dded5567-50b3-4399-8d2d-656442d147d",
      title: "Иван И.",
    },
    {
      id: "dded5567-50b3-4399-8d2d-65644d147da",
      title: "ЗАО Организация",
    },
    {
      id: "dded5567-50b3-4399-8d2d-656442d147da",
      title: "ОАО Компания",
    },
  ],
  userName: "Босс",
  roles: [
    {
      id: 1,
      name: "admin",
    },
    {
      id: 2,
      name: "owner",
    },
    {
      id: 3,
      name: "analytic",
    },
    {
      id: 4,
      name: "engineer",
    },
    {
      id: 5,
      name: "analyst_system",
    },
    // {
    //   id: 6,
    //   name: "demo_test",
    // },
    // {
    //   id: 7,
    //   name: "customer",
    // },
    // {
    //   id: 8,
    //   name: "abonent",
    // },
  ],
};

export const aboutUsers = [
  {
    createdTime: "2022-05-10T10:00:00",
    email: "besk1@mail.ru",
    id: 1,
    isActive: true,
    phone: "89105486620",
    tenant: {
      email: "test1@mts.ru",
      id: "dded5567-50b3-4399-8d2d-656442d147da",
      phone: null,
      title: "ОАО Компания",
    },
    tenants: [
      {
        id: "7d4cc160-2772-11ec-9145-e180c6905313",
        title: "ООО БашкирЭнерго",
      },
      {
        id: "b1988760-0ecd-11eb-9918-e55f481af088",
        title: "ООО Иванофф Инвестиции",
      },
      {
        id: "b1988760-0ecd-11eb-9918-e55f481af088_202",
        title: "Иванов Иван Иванович",
      },
      {
        id: "dded5567-50b3-4399-8d2d-656442d147d",
        title: "Иван И.",
      },
      {
        id: "dded5567-50b3-4399-8d2d-65644d147da",
        title: "ЗАО Организация",
      },
      {
        id: "dded5567-50b3-4399-8d2d-656442d147da",
        title: "ОАО Компания",
      },
    ],
    userName: "Босс",
    roles: [
      //   {
      //     id: 1,
      //     name: "admin",
      //   },
      {
        id: 2,
        name: "owner",
      },
      {
        id: 3,
        name: "analytic",
      },
      //   {
      //     id: 5,
      //     name: "analyst_system",
      //   },
    ],
  },
  {
    createdTime: "2022-05-10T10:00:00",
    email: "besk2@mail.ru",
    id: 2,
    isActive: false,
    phone: "9105486650",
    tenant: {
      email: "test1@mts.ru",
      id: "dded5567-50b3-4399-8d2d-656442d147da",
      phone: null,
      title: "ОАО Компания",
    },
    tenants: [
      {
        id: "7d4cc160-2772-11ec-9145-e180c6905313",
        title: "ООО БашкирЭнерго",
      },
      {
        id: "b1988760-0ecd-11eb-9918-e55f481af088",
        title: "ООО Иванофф Инвестиции",
      },
      {
        id: "b1988760-0ecd-11eb-9918-e55f481af088_202",
        title: "Иванов Иван Иванович",
      },
      {
        id: "dded5567-50b3-4399-8d2d-656442d147d",
        title: "Иван И.",
      },
      {
        id: "dded5567-50b3-4399-8d2d-65644d147da",
        title: "ЗАО Организация",
      },
      {
        id: "dded5567-50b3-4399-8d2d-656442d147da",
        title: "ОАО Компания",
      },
    ],
    userName: "Босс 1",
    roles: [
      {
        id: 1,
        name: "admin",
      },
      {
        id: 2,
        name: "owner",
      },
      {
        id: 3,
        name: "analytic",
      },
    ],
  },
];
