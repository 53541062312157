import React, { useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { makeAbonentSnCard, makeManagAbonentCard } from "../utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { PopupAction } from "components/popup-action/popup-action";
import { ModalAction } from "components/modal-action/modal-action";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { SContainerMobile, SContent } from "../styles";

export const AbonentMobCard = ({ data, dataPopup }) => {
  const { isMobile } = useContext(ContextSettings);

  return (
    <SContainerMobile>
      <SContent>
        {Object.entries(makeManagAbonentCard(data)).map(([key, val]) => {
          return key === "status" ? (
            val.value
          ) : (
            <div key={key}>
              {key === "contract_num" ? (
                <CopyToClipboard text={val.value as string} variant="p3_bold" />
              ) : key === "accepted" ? (
                val.value
              ) : (
                <CusTypo variant={key === "userName" ? "p3_bold" : "c1_regular"} font="comp">
                  {val.value}
                </CusTypo>
              )}
            </div>
          );
        })}
      </SContent>
      {isMobile() ? (
        <ModalAction items={dataPopup} />
      ) : (
        <PopupAction items={dataPopup} view="light" />
      )}
    </SContainerMobile>
  );
};

export const AbonentSnMobCard = ({ data, dataPopup }) => {
  const { isMobile } = useContext(ContextSettings);

  return (
    <SContainerMobile>
      <SContent>
        {Object.entries(makeAbonentSnCard(data)).map(([key, val]) => {
          return key === "status" ? (
            val.value
          ) : (
            <div key={key}>
              {key === "sn" ? (
                <CopyToClipboard text={val.value} variant="p3_bold" />
              ) : (
                <CusTypo variant="c1_regular" font="comp">
                  {val.value}
                </CusTypo>
              )}
            </div>
          );
        })}
      </SContent>
      {isMobile() ? (
        <ModalAction items={dataPopup} />
      ) : (
        <PopupAction items={dataPopup} view="light" />
      )}
    </SContainerMobile>
  );
};
