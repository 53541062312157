import { toast } from "react-toastify";
import { Toast } from "components/toast/toast";

export const HARDCODE_REJECT_MES = [
  "Неверно указан серийный номер устройства",
  "Неверно указан номер договора",
  "Неверно указаны ФИО договора",
  "Другое",
];

export const toastInfoSucc = () => {
  toast(<Toast title="Информация обновлена" />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastInfoErr = () => {
  toast(<Toast title="Информация не обновлена" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
