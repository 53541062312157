import { useContext } from "react";

import { ContextSettings } from "context/context-settings";
import { Footer } from "components/footer/footer";
import { FormConnectDevice } from "components/forms/abonent-connect-device/form-connect-device";
import { AskueAbonentMain } from "pages/askue/askueAbonentMain";
import { useGetAbonentStatus } from "hooks/api/abonent";
import { EStatus } from "components/forms/abonent-connect-device/utils";
import { NotifySection } from "components/notification-section/notification-section";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { useUpdateStatusApplication } from "hooks/api/management";
import { SMain, SContainer, SNotify } from "./styles";

export const AbonentAskue = () => {
  const { data, isLoading } = useGetAbonentStatus();
  const { profile } = useContext(ContextSettings);
  const mutation = useUpdateStatusApplication();

  if (isLoading) {
    return null;
  }

  return (
    <>
      <SMain>
        <SContainer>
          {data?.status === EStatus.success ? (
            <SNotify>
              <NotifySection
                title="Заявка успешно отправлена"
                subtitle={`Мы в процессе обработки заявки на подключение устройств. \nСроки рассмотрения заявки обычно занимают 1-2 рабочих дня.`}
              />
            </SNotify>
          ) : data?.status === EStatus.accept ? (
            <AskueAbonentMain />
          ) : data?.status === EStatus.error ? (
            <SNotify>
              <NotifySection
                title="Заявка отклонена"
                subtitle={`Неверно указан серийный номер устройства. \nПожалуйста, попробуйте отправить заявку еще раз.`}
                isError
              />
              <Spacer value="24px" />
              <div>
                <MTSButton
                  size="M"
                  variant="secondary"
                  onClick={() => {
                    mutation.mutate({
                      status: EStatus.new,
                      user_id: profile!.id,
                    });
                  }}
                >
                  Попробовать еще
                </MTSButton>
              </div>
            </SNotify>
          ) : (
            <FormConnectDevice />
          )}
        </SContainer>
      </SMain>

      <Footer />
    </>
  );
};
