import { useQuery, useMutation, useInfiniteQuery } from "react-query";
import { IAskueCheckSnRes, IAbonentStatusRes, IAskueCheckSnReq } from "interface/abonent";
import {
  getCheckSn,
  getAddAbonentDevice,
  getAskueStatus,
  getAskueAbonentEvents,
  getAbonentDeviceList,
  getAddNewAbonentDevice,
} from "services/askue";
import { IAbntAskueListResponse, IAskueEventRequest, IAskueEventResponse } from "interface/askue";
import { Keys, query_objects_limit } from "./const";

export function useCheckSn(data: IAskueCheckSnReq, isEnabled: boolean) {
  return useQuery<any, any, IAskueCheckSnRes>([data.sn], () => getCheckSn(data), {
    enabled: isEnabled,
  });
}

export function useAddAbonentDevice() {
  return useMutation<any, any, any>((a) => getAddAbonentDevice(a), {});
}

export function useGetAbonentStatus(isEnabled?: boolean | undefined) {
  return useQuery<any, any, IAbonentStatusRes>([Keys.ABONENT], () => getAskueStatus(), {
    enabled: isEnabled ?? true,
  });
}

export function useAskueAbonentEvents(filters?: IAskueEventRequest | null) {
  return useInfiniteQuery<any, { description?: string }, IAskueEventResponse>(
    [Keys.ABONENT, "events", filters],
    ({ pageParam = 1 }) =>
      getAskueAbonentEvents({ ...filters, page_num: pageParam, obj_cnt: query_objects_limit }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.offset >= lastPage.count
          ? undefined
          : lastPage.offset / lastPage?.limit + 1;
      },
    },
  );
}

export function useGetAbonentDeviceList(id?: string | number) {
  return useQuery<any, any, IAbntAskueListResponse>([Keys.ABONENT, "abonentDeviceList", id], () =>
    getAbonentDeviceList(id),
  );
}

export function useAddNewAbonentDevice() {
  return useMutation<any, any, any>([Keys.ABONENT, "addNewAbonentDevice"], (a) =>
    getAddNewAbonentDevice(a),
  );
}
