import React, { useRef, useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";

import { ICardInfo } from "components/card-info/cardInfo.component";
import { MTSModal } from "components/mts-modal/mts-modal";
import { ContextSettings } from "context/context-settings";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { AbonentMobCard } from "./subcomponents";
import { Loader } from "components/loader/loader";
import { makeAbonentInfo, makeActiveAbonentInfo } from "./utils";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { AbonentSnTable } from "components/management-abonent/abonentSnTable.component";
import { useGetAbonentDeviceList } from "hooks/api/abonent";
import { TableSort } from "components/table/table-sort/table-sort";
import Icon from "components/icons";
import { FormAddSn } from "components/forms/abonent-management/form-add-sn";
import { FormAcceptApplication } from "components/forms/abonent-management/form-accept-application";
import { FormRejectApplication } from "components/forms/abonent-management/form-reject-application";
import { FormDeleteAbonent } from "components/forms/abonent-management/form-del-abonent";
import { FormEditAbonent } from "components/forms/abonent-management/form-edit-abonent";
import { EAbonentStatus } from "components/management-abonent/const";
import { TABLE_SORT_DEFAULT_ABNT_SN } from "./const";
import { SInner } from "../styles";
import { SBtns, SBtnsMob, SCont, SSpecLi, STabItem, STabs } from "./styles";

export const AbonentAccordionDetails: React.FC<ICardInfo> = ({ data, calcHeightInner }) => {
  const refInner = useRef<any>(null);

  useEffect(() => {
    if (refInner?.current) {
      const elem = refInner?.current;
      if (calcHeightInner) {
        calcHeightInner(elem?.getBoundingClientRect().height);
      }
    }
  });

  return (
    <SCont onClick={(event) => event.stopPropagation()} ref={refInner}>
      {data.accepted === EAbonentStatus["Ожидает подтверждения"] ? (
        <AbonDetailsWaitConfirm data={data} />
      ) : data.accepted === EAbonentStatus["Заявка отклонена"] ? (
        <AbonDetailsWaitReject data={data} />
      ) : (
        <AbonDetailsAccept data={data} />
      )}
    </SCont>
  );
};

export const AbonentMobileCard = ({ row }) => {
  const { user_id } = row.original;

  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAddDevice, setShowModalAddDevice] = useState(false);

  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);
  const toggleShowModalAddDevice = () => setShowModalAddDevice(!showModalAddDevice);

  const editFunc = {
    title: "Редактировать данные",
    onClick: toggleShowModalEdit,
  };

  const addFunc = {
    title: "Добавить доступное устройсво",
    onClick: toggleShowModalAddDevice,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
  };

  const dataPopup = [editFunc, addFunc, delFunc];

  return (
    <>
      <AbonentMobCard data={row.original} dataPopup={dataPopup} />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteAbonent onCancel={toggleShowModalDelete} id={user_id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditAbonent onCancel={toggleShowModalEdit} data={row.original} />
      </MTSModal>
      <MTSModal open={showModalAddDevice} close={toggleShowModalAddDevice}>
        <FormAddSn userId={user_id} onCancel={toggleShowModalAddDevice} />
      </MTSModal>
    </>
  );
};

export const AbonDetailsWaitConfirm: React.FC<ICardInfo> = ({ data }) => {
  const [showModalAccept, setShowModalAccept] = useState(false);
  const [showModalReject, setShowModalReject] = useState(false);

  const toggleShowModalAccept = () => {
    setShowModalAccept(!showModalAccept);
  };

  const toggleShowModalReject = () => {
    setShowModalReject(!showModalReject);
  };

  return !isEmpty(data) ? (
    <>
      <ul>
        {Object.entries(makeAbonentInfo(data))?.map(([key, val]) => {
          const textVal =
            key === "sn" && Array.isArray(val.text)
              ? val.text.join(", ")
              : val.text ?? EMPTY_CELL_VALUE;

          return !isEmpty(textVal) ? (
            <SSpecLi key={key}>
              <div>
                {key === "sn" ? (
                  <CusTypo variant="c1_regular">
                    {val.text.length > 1 ? "Серийные номера устройств" : val.title}
                  </CusTypo>
                ) : (
                  <CusTypo variant="c1_regular">{val.title}</CusTypo>
                )}
              </div>
              {val["isCanCopy"] ? (
                <CopyToClipboard text={textVal as string} />
              ) : (
                <CusTypo variant="c1_medium">{textVal}</CusTypo>
              )}
            </SSpecLi>
          ) : null;
        })}
      </ul>
      <Spacer value="24px" />
      <SBtns>
        <MTSButton size="M" variant="primary" type="submit" onClick={toggleShowModalAccept}>
          Принять заявку
        </MTSButton>
        <MTSButton size="M" variant="secondary" onClick={toggleShowModalReject}>
          Отклонить заявку
        </MTSButton>
      </SBtns>
      <MTSModal open={showModalAccept} close={toggleShowModalAccept}>
        <FormAcceptApplication onCancel={toggleShowModalAccept} data={data} />
      </MTSModal>
      <MTSModal open={showModalReject} close={toggleShowModalReject}>
        <FormRejectApplication onCancel={toggleShowModalReject} data={data} />
      </MTSModal>
    </>
  ) : (
    <CusTypo variant="c1_medium">Нет данных</CusTypo>
  );
};

export const AbonDetailsAccept: React.FC<ICardInfo> = ({ data }) => {
  const { isDesktop } = useContext(ContextSettings);
  const [showTab, setShowTab] = useState<"devices" | "info">("devices");
  const [columnSort, setColumnSort] = useState<any>([]);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [showModalAddSn, setShowModalAddSn] = useState(false);
  const { data: devices, isLoading } = useGetAbonentDeviceList(data.user_id);

  const toggleShowModalAddSn = () => {
    setShowModalAddSn(!showModalAddSn);
  };
  const handleSwitchTabs = (value: "devices" | "info") => {
    setShowTab(value);
  };

  const handleColumnSort = (sorting) => setColumnSort(sorting);

  return (
    <>
      <div>
        <STabs>
          <STabItem isActive={showTab === "devices"} onClick={() => handleSwitchTabs("devices")}>
            <CusTypo variant="p4_regular">Доступные устройства</CusTypo>
          </STabItem>
          <STabItem isActive={showTab === "info"} onClick={() => handleSwitchTabs("info")}>
            <CusTypo variant="p4_regular">Дополнительная информация</CusTypo>
          </STabItem>
        </STabs>
      </div>
      <SInner>
        {showTab === "devices" ? (
          <>
            {!isDesktop() ? (
              <SBtnsMob>
                <MTSButton
                  size="S"
                  variant="secondary"
                  onClick={() => setIsOpenSort(true)}
                  format="icon"
                  icon={<Icon.Sort sx={{ width: 16, height: 16 }} />}
                />
                <TableSort
                  items={TABLE_SORT_DEFAULT_ABNT_SN}
                  isOpen={isOpenSort}
                  close={() => setIsOpenSort(false)}
                  onApplySort={handleColumnSort}
                  view="modal"
                />
              </SBtnsMob>
            ) : null}

            {isLoading ? (
              <Loader size="S_16" />
            ) : !isEmpty(devices) ? (
              <AbonentSnTable
                rawData={devices?.results ?? []}
                sortByColumn={columnSort}
                savedId={data.user_id}
              />
            ) : (
              <SSpecLi>
                <CusTypo variant="c1_regular">Нет данных для отображения</CusTypo>
              </SSpecLi>
            )}
          </>
        ) : !isEmpty(data) ? (
          <ul>
            {Object.entries(makeActiveAbonentInfo(data))?.map(([key, val]) => {
              const textVal = val.text ?? EMPTY_CELL_VALUE;
              return (
                <SSpecLi key={key}>
                  <div>
                    <CusTypo variant="c1_regular">{val.title}</CusTypo>
                  </div>
                  {val["isCanCopy"] ? (
                    <CopyToClipboard text={textVal as string} />
                  ) : (
                    <CusTypo variant="c1_medium">{textVal}</CusTypo>
                  )}
                </SSpecLi>
              );
            })}
          </ul>
        ) : null}
        <Spacer value="24px" />
        <SBtns>
          <MTSButton size="M" variant="primary" type="submit" onClick={toggleShowModalAddSn}>
            Добавить устройство
          </MTSButton>
        </SBtns>
        <MTSModal open={showModalAddSn} close={toggleShowModalAddSn}>
          <FormAddSn userId={data.user_id} onCancel={toggleShowModalAddSn} />
        </MTSModal>
      </SInner>
    </>
  );
};

export const AbonDetailsWaitReject: React.FC<ICardInfo> = ({ data }) => {
  return (
    <SInner>
      {!isEmpty(data) ? (
        <ul>
          {Object.entries(makeAbonentInfo(data))?.map(([key, val]) => {
            const textVal =
              key === "sn" && Array.isArray(val.text)
                ? val.text.join(", ")
                : val.text ?? EMPTY_CELL_VALUE;
            return (
              <SSpecLi key={key}>
                <div>
                  <CusTypo variant="c1_regular">{val.title}</CusTypo>
                </div>
                {val["isCanCopy"] ? (
                  <CopyToClipboard text={textVal as string} />
                ) : (
                  <CusTypo variant="c1_medium">{textVal}</CusTypo>
                )}
              </SSpecLi>
            );
          })}
        </ul>
      ) : (
        <CusTypo variant="c1_medium">Нет данных для отображения</CusTypo>
      )}
    </SInner>
  );
};
