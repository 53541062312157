import theme from "theme";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Accordion } from "@mui/material";

export const SAccordion = styled(Accordion)<{ expanded?: boolean }>`
  z-index: 1;
  position: absolute;
  left: 0;
  top: 92px;
  min-height: 0;
  height: auto;
  width: 100%;
  padding: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  ${(props) =>
    props.expanded
      ? css`
          border-top: 1px solid ${props.theme.mtsColor.grey.greyLight};
        `
      : ""};

  &.MuiAccordion-root {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: none;
    margin: 0;
    &:before {
      display: none;
    }
  }
`;

export const SRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
`;

export const SWrap = styled.div`
  padding: 5px;

  & svg {
    fill: ${theme.mtsColor.grey.greyMedium};
    transition: all 0.3 ease;

    &:hover {
      fill: ${theme.mtsColor.grey.greyDark};
    }
  }
`;
