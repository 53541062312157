import * as yup from "yup";
import validationMethod from "../common-validation";
import { IMngAbonentResponse } from "interface/management";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";

export const defaultValues = {
  sn: [{ name: null }],
};

export const makeDefaultForEdit = (data: IMngAbonentResponse | null) => {
  return {
    username: data?.username,
    user_id: data?.user_id,
    contract_num: data?.contract_num,
    phone: data?.phone,
    email: data?.email,
  };
};

export const schema = yup.object().shape({
  sn: yup.array().of(
    yup.object().shape({
      name: yup.string().required(REQUIRED),
    }),
  ),
});

export const schemaEdit = yup.object().shape({
  contract_num: yup.string().required(REQUIRED),
  username: yup
    .mixed()
    .test("requiredUserName", REQUIRED, validationMethod.requiredUserName)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
  phone: yup
    .mixed()
    .test("correctPhone", INCORRECT, validationMethod.correctPhone)
    .test("requiredPhone", REQUIRED, validationMethod.requiredPhone),
  email: yup
    .mixed()
    .required(REQUIRED)
    .test("correctEmail", INCORRECT, validationMethod.correctEmail),
});

export const schemaUpdateStatus = yup.object().shape({
  reason: yup.string().required("Поле обязательно для заполнения"),
  comment: yup.string().when("reason", {
    is: "Другое",
    then: yup.string().required(REQUIRED),
    otherwise: yup.string(),
  }),
});
