import format from "date-fns/format";
import { isEmpty } from "lodash";

import { IDeviceList } from "interface/anomalies";
import { MTSStatus } from "components/mts-status/mts-status";
import { Enlarge, Expandable, SelectAction, StateCode } from "./subComponents";
import { RES_COLUMN } from "pages/anomalies/const";

export const constructTableData = (draft: IDeviceList[]) =>
  draft?.map((d) => {
    const { telebot_request_last_dt, priem, id_pp } = d;

    return {
      id: id_pp,
      ...d,
      telebot_request_last_dt: new Date(telebot_request_last_dt ?? 0),
      [RES_COLUMN]: priem,
    };
  });

interface IProp {
  columns: any[];
  active: { [key: string]: boolean };
}

export const constructColumns = ({ columns, active }: IProp) => {
  return columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "factory_count_num"
        ? {
            Cell: ({ value, row }) => (
              <Expandable
                value={value}
                row={row}
                isOpen={active[row.original.id] ?? false}
                code={row.original.state_code}
              />
            ),
          }
        : singleColumn.accessor === "telebot_request_last_result"
        ? {
            Cell: ({ value }) => MTSStatus(value, "medium"),
          }
        : singleColumn.accessor === "telebot_request_last_dt"
        ? {
            Cell: ({ value }) => Enlarge({ value: format(new Date(value ?? 0), "dd.MM.yyyy") }),
          }
        : singleColumn.accessor === "state_code"
        ? {
            Cell: ({ value }) => <StateCode code={value} />,
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <SelectAction code={row.original.state_code} id={row.original.id} />,
          }
        : { Cell: Enlarge }),
    };
  });
};

export const anomaliesGlobFilter = (rows, columnIds, globFilt) => {
  if (globFilt?.archiveFilter?.length === 0 && globFilt?.text?.length === 0) {
    return rows;
  }
  const res = !isEmpty(globFilt.archiveFilter)
    ? rows.filter((a) => {
        const { state_code } = a.original;
        return state_code && globFilt.archiveFilter.includes(state_code);
      })
    : rows;

  return globFilt?.text?.length > 0
    ? res.filter((b) => {
        return JSON.stringify(b.original).toLowerCase().includes(globFilt.text.toLowerCase());
      })
    : res;
};
