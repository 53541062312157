import { AbonentMobileCard } from "components/card-info/abonent/abonent.component";
import { AbonentSnMobileCard } from "components/card-info/abonent/mobAbonents.component";
import { RenderFilter } from "components/table/render-filter/renderFilter.component";
import { FILTER_BY_STATUS } from "pages/managementAbonent/const";

export enum EAbonentStatus {
  "Ожидает подтверждения" = 1,
  "Заявка отклонена" = 2,
  Активен = 3,
}

function dontFilter(rows, columnIds, filterValue) {
  return rows;
}

function multiSelectStatus(rows, columnIds, filterValue) {
  return filterValue.length === 0
    ? rows
    : rows.filter((row) => {
        const { accepted } = row.original;
        return accepted && filterValue.some((status) => EAbonentStatus[accepted].includes(status));
      });
}

export const tableStructure = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Номер договора"
        withPadding
      />
    ),
    accessor: "contract_num",
    minWidth: 260,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="ФИО"
        withPadding
      />
    ),
    accessor: "username",
    minWidth: 280,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Дата"
        withPadding
      />
    ),
    accessor: "created_time",
    minWidth: 150,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Статус"
        withPadding
      />
    ),
    accessor: "accepted",
    minWidth: 264,
    canFilter: false,
    disableFilters: true,
    filter: multiSelectStatus,
  },
  {
    accessor: "action",
    minWidth: 56,
    width: "100%",
    disableSortBy: true,
  },
];

export const tableMobStructure = [
  {
    accessor: "contract_num",
    Row: AbonentMobileCard,
  },
  {
    accessor: "username",
    canFilter: false,
    disableFilters: true,
    filter: dontFilter,
  },
  {
    accessor: "created_time",
  },
  {
    accessor: FILTER_BY_STATUS,
    canFilter: true,
    filter: multiSelectStatus,
  },
  {
    accessor: "action",
    width: "100%",
    disableSortBy: true,
  },
];

export const tableStructureSn = [
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Серийный номер"
        withPadding
      />
    ),
    accessor: "sn",
    minWidth: 160,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Модель"
        withPadding
      />
    ),
    accessor: "meter_name",
    minWidth: 250,
  },
  {
    Header: ({ column }) => (
      <RenderFilter
        isSorted={column.isSorted}
        isSortedDesc={column.isSortedDesc || false}
        title="Адрес"
        withPadding
      />
    ),
    accessor: "address",
    minWidth: 300,
  },
  {
    accessor: "action",
    minWidth: 56,
    width: "100%",
    disableSortBy: true,
  },
];

export const tableSnMobStructure = [
  {
    accessor: "sn",
    Row: AbonentSnMobileCard,
  },
  {
    accessor: "meter_name",
  },
  {
    accessor: "address",
  },
  {
    accessor: "action",
    width: "100%",
    disableSortBy: true,
  },
];
