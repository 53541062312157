export const mock_data = [
  {
    comment: "",
    tenant_id: "11111",
    created_time: "2025-03-04T15:56:10.340Z",
    username: "Стрельников Иван Александрович",
    phone: "+79801002030",
    email: "strelnikov@mail.ru",
    user_id: 1,
    accepted: 1,
    tenant: "string",
    sn: [333111222, 3331112344],
    contract_num: " 123-ОПРС08-ЛС-234",
  },
  {
    comment: "Коммент 2",
    tenant_id: "22222",
    created_time: "2025-02-04T15:56:10.340Z",
    username: "Константинопольский Константин Иванович",
    phone: "+79801502233",
    email: "konstaninop@mail.ru",
    user_id: 2,
    accepted: 3,
    tenant: "ТОСК",
    sn: [48379595],
    contract_num: " 125-ОПРС08-ЛС-234",
  },
  {
    comment: "Коммент 3",
    tenant_id: "22222",
    created_time: "2025-05-04T15:56:10.340Z",
    username: "Веденеева Александра Петровна",
    phone: "+79801504466",
    email: "vedeneva@mail.ru",
    user_id: 3,
    accepted: 2,
    tenant: "ТОСК",
    sn: [483721445],
    contract_num: " 140-ОПРС08-ЛС-234",
  },
];
