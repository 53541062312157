import { useContext } from "react";

import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { Loader } from "components/loader/loader";
import { AskueTable } from "components/askue/askueTable/askueTable.component";
import { ContextSettings } from "context/context-settings";
import { CusTypo } from "components/cusTypo/custom-typography";
import { useGetAbonentDeviceList } from "hooks/api/abonent";
import { links_devices } from "./const";
import { SArticle, SSection, STitle } from "./styles";

interface IProp {
  isDemo?: boolean;
}

export const AskueAbonentDeviceList: React.FC<IProp> = ({ isDemo }) => {
  const { isDesktop } = useContext(ContextSettings);
  const { data, isLoading } = useGetAbonentDeviceList();

  return (
    <>
      <div>
        <SSection>
          <Breadcrumbs links={links_devices} />
          <SArticle isFullTitle={isDemo}>
            <STitle>
              <CusTypo
                variant={isDesktop() ? "h2_medium" : "h4_medium"}
                font="wide"
                styles={{ margin: "0 24px 0 0" }}
              >
                Список устройств
              </CusTypo>
            </STitle>
          </SArticle>
        </SSection>
        <AskueTable rawData={data?.results ?? []} />
      </div>

      <Footer />
      {isLoading ? <Loader isFullPage /> : null}
    </>
  );
};
