import { useContext, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { createPortal } from "react-dom";
import * as L from "leaflet";
import { isEmpty, isArray } from "lodash";
import SelectArea from "leaflet-area-select";

import useScript from "utils/useScript";
import { useDeleteDeparture, useDeparturesMarkers, useMiningMarkers } from "hooks/api/map";
import { SlideIn } from "components/table/slideIn/slideIn.component";
import Icon from "components/icons";
import { LayersControl } from "./subcomponents/layersControl";
import { MinersList } from "./subcomponents/minersList";
import { FormDepartures } from "../forms/departures/form-departures";
import {
  PopContent,
  MemoizedMap,
  MinerPopup,
  SimpleSearch,
  toastDel,
  toastDepSubmtFail,
  toastDepSubmtSucc,
  toastOnEmpty,
  toastOnError,
  LeftPanel,
} from "./subcomponents/mapElements";
import {
  calcDefaultFilter,
  drawMinerMarker,
  drawPolygon,
  makeFilterItems,
  makeLayers,
} from "./utils";
import { IFilter, TableFilter } from "components/table/table-filter/table-filter";
import { MTSModal } from "components/mts-modal/mts-modal";
import { ZoomLocation } from "./subcomponents/zoomLocation";
import { IDeparturesRequest, IMapMarker, IMapMiner, IMinersRequest } from "interface/map";
import { ContextSettings } from "context/context-settings";
import { composeBackendFilter } from "pages/mapPage/const";
import {
  ELayers,
  IFormDepartures,
  IMapComponent,
  IPopContent,
  ISel,
  composeDefaultState,
  mapOptions,
  reducer,
} from "./const";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { SERVICES } from "stream-constants/keys-and-constants";
import { SCont, SPopup, SToolBtn, STools, forceCss } from "./styles";

export const Map: React.FC<IMapComponent> = ({
  filterOpen,
  filterToggle,
  archive,
  handleDepFilter,
  depFilter,
  handleMiningFilter,
  miningFilter,
  isMinersPage,
  isAnoPage,
}) => {
  const { profile, isDesktop, isTablet } = useContext(ContextSettings);
  const [markerPopContent, setMarkerPopContent] = useState<IPopContent | null>(null);
  const [minerPopContent, setMinerPopContent] = useState<IPopContent | null>(null);
  const [groupPopContent, setGroupPop] = useState<IMapMarker[]>([]);
  const [isFormOpen, setFormOpen] = useState(false);
  const [isLayersOpen, setLayers] = useState(false);
  const [isPanelOpen, setPanel] = useState(false);
  const [reducerState, dispatchReducer] = useReducer(
    reducer,
    composeDefaultState(isMinersPage, isAnoPage),
  );
  const [form, setForm] = useState<IFormDepartures[] | null>(null);
  const [selMarkers, setSel] = useState<ISel[]>([]);
  const [zoom, setZoom] = useState(10);
  const [isSelecting, setSelect] = useState(false);

  const {
    data: departures,
    mutate: mutateDep,
    isSuccess: depIsSuccess,
    isError: depIsError,
    isLoading: depIsLoading,
  } = useDeparturesMarkers();

  const {
    data: miners,
    mutate: mutateMin,
    isSuccess: minIsSuccess,
    isError: minIsError,
    isLoading: minIsLoading,
  } = useMiningMarkers();

  const { isLoaded: isYaApiLoad } = useScript({
    src: `https://api-maps.yandex.ru/2.1/?apikey=${process.env.REACT_APP_YANDEX_KEY}&lang=ru_RU`,
  });

  const { isLoaded: isYaTilesLoad } = useScript({
    src: "/YandexTilesForLeaflet.js",
  });

  const { mutate: delDeparture } = useDeleteDeparture();

  const devices = useMemo(
    () =>
      departures
        ?.filter((e) => (e.id_pp && e.adress_too) ?? false)
        .map((e) => `${e.id_pp}, ${e.adress_too}, № ${e.sn ?? "-"}`),
    [departures],
  );

  const mapRef = useRef<any>(null);
  const depLayer = useRef<any>(null);
  const anoLayer = useRef<any>(null);
  const minPolyLayer = useRef<any>(null);
  const minMarkersLayer = useRef<any>(null);
  const minMarkersPane = useRef<any>(null);
  const isFirstDraw = useRef<boolean>(true);

  const storageFilter: IFilter =
    (StorageAPI.get(EStorageKeys.DEP_FILTER) &&
      JSON.parse(StorageAPI.get(EStorageKeys.DEP_FILTER))) ||
    [];

  const isStorageFilterAvail = storageFilter && storageFilter?.some((a) => !isEmpty(a.value));

  const openCheck = () => {
    setFormOpen(true);
  };

  const handleSearchClick = (a: IFormDepartures) => {
    mapRef.current.setView([a.lat, a.lng], 14);
    setForm([a]);
    openCheck();
  };

  const clearSelection = () => {
    setFormOpen(false);
    setSel([]);
    setForm(null);
    selMarkers.forEach((l) => l._icon.classList.remove("marker--selected"));
  };

  const departureSubmitSuccess = () => {
    mapRef.current.removeLayer(depLayer.current);
    mutateDep(composeBackendFilter<IDeparturesRequest>(depFilter, "dep"));
    setSel([]);
    setForm(null);
  };

  const cancelDeparture = (id) => {
    delDeparture(
      { idPP: isArray(id) ? id : [id] },
      {
        onSuccess: () => {
          departureSubmitSuccess();
          toastDepSubmtSucc();
        },
        onError: () => {
          toastDepSubmtFail();
        },
      },
    );
  };
  useEffect(() => {
    mapRef.current = new L.Map("myMap", mapOptions as any);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    SelectArea;
    minMarkersPane.current = mapRef.current.createPane("miningMarkersPane");
  }, []);

  useEffect(() => {
    if (isYaApiLoad && isYaTilesLoad) {
      // @ts-ignore
      L.yandex().addTo(mapRef.current);
    }
  }, [isYaApiLoad, isYaTilesLoad]);

  const depState = reducerState[ELayers.DEP];
  const anoState = reducerState[ELayers.ANO];
  const minState = reducerState[ELayers.MIN];

  useEffect(() => {
    if (profile?.services_ch.includes(SERVICES.LOSSES)) {
      const newFilt = composeBackendFilter<IDeparturesRequest>(depFilter, "dep");
      if (!isMinersPage) {
        if (newFilt != null) {
          mutateDep(newFilt);
        }
      } else {
        mutateDep(newFilt);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(depFilter), mutateDep, isMinersPage, profile?.services_ch]);

  useEffect(() => {
    if (profile?.services_ch.includes(SERVICES.MINING)) {
      const newFilt = composeBackendFilter<IMinersRequest>(miningFilter, "min");
      if (isMinersPage) {
        if (newFilt != null) {
          mutateMin(newFilt);
        }
      } else {
        mutateMin(newFilt);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(miningFilter), mutateMin, isMinersPage, profile?.services_ch]);

  const filterItems = makeFilterItems(minState?.visible, depState?.visible, archive);

  useEffect(() => {
    depLayer.current != null && mapRef.current.removeLayer(depLayer.current);
    if (depIsSuccess && depState?.visible) {
      const filtered = departures?.filter((a) => a.lat && a.lng);

      if (isEmpty(filtered)) {
        toastOnEmpty(ELayers.DEP);
      } else {
        const prepared = makeLayers({
          arr: filtered,
          singleSetter: setMarkerPopContent,
          groupSetter: setGroupPop,
          slideSetter: setPanel,
          selectionSetter: setSel,
          entity: "dep",
        });

        const layerGroupDepartures = L.layerGroup(prepared);
        depLayer.current = layerGroupDepartures;
        layerGroupDepartures.addTo(mapRef.current);

        if (isFirstDraw.current) {
          let itm = filtered?.[0];
          if (!isEmpty(itm)) {
            const center = [itm!.lat, itm!.lng];
            mapRef.current.panTo(center);
          }
          isFirstDraw.current = false;
        }
      }
    }
  }, [depIsSuccess, departures, depState]);

  useEffect(() => {
    anoLayer.current != null && mapRef.current.removeLayer(anoLayer.current);
    if (depIsSuccess && anoState?.visible) {
      const filtered = departures?.filter((a) => a.lat && a.lng);

      if (isEmpty(filtered)) {
        toastOnEmpty(ELayers.ANO);
      } else {
        const prepared = makeLayers({
          arr: filtered,
          singleSetter: setMarkerPopContent,
          groupSetter: setGroupPop,
          slideSetter: setPanel,
          selectionSetter: setSel,
          entity: "ano",
        });

        const layerGroupAnomalies = L.layerGroup(prepared);
        anoLayer.current = layerGroupAnomalies;
        layerGroupAnomalies.addTo(mapRef.current);

        if (isFirstDraw.current) {
          let itm = filtered?.[0];
          if (!isEmpty(itm)) {
            const center = [itm!.lat, itm!.lng];

            mapRef.current.panTo(center);
          }
          isFirstDraw.current = false;
        }
      }
    }
  }, [depIsSuccess, departures, anoState]);

  useEffect(() => {
    if (minIsSuccess && minState?.visible) {
      minPolyLayer.current != null && mapRef.current.removeLayer(minPolyLayer.current);
      minMarkersLayer.current != null && mapRef.current.removeLayer(minMarkersLayer.current);

      if (isEmpty(miners)) {
        toastOnEmpty(ELayers.MIN);
      } else {
        const layerMarkers = miners?.map((point) =>
          drawMinerMarker({
            point,
            stateSetter: setMinerPopContent,
          }),
        );

        const layerGroupMinMarkers = L.layerGroup(layerMarkers);
        minMarkersLayer.current = layerGroupMinMarkers;
        layerGroupMinMarkers.addTo(mapRef.current);

        const layerPolygons = miners?.map((point) =>
          drawPolygon({
            point,
            stateSetter: setMinerPopContent,
          }),
        );
        const layerGroupMinPolygons = L.layerGroup(layerPolygons);
        minPolyLayer.current = layerGroupMinPolygons;

        if (isFirstDraw.current) {
          let center = [miners?.[0]?.mean_x, miners?.[0]?.mean_y].reverse();
          if (!isEmpty(center) && center.every((e) => e != null)) {
            mapRef.current.panTo(center);
          }
          isFirstDraw.current = false;
        }
      }
    }
  }, [minIsSuccess, miners, minState]);

  useEffect(() => {
    if (depIsError || minIsError) toastOnError();
  }, [depIsError, minIsError]);

  useEffect(() => {
    mapRef.current.on("zoomend", ({ target }) => {
      setZoom(target.getZoom());
    });
  }, []);

  useEffect(() => {
    if (minState?.visible) {
      if (zoom >= 16) {
        minPolyLayer.current?.addTo(mapRef.current);
        mapRef.current?.removeLayer(minMarkersLayer.current ?? {});
        minMarkersPane.current.style.display = "none";
      } else {
        minMarkersLayer.current?.addTo(mapRef.current);
        minMarkersPane.current.style.display = "";
        mapRef.current?.removeLayer(minPolyLayer.current ?? {});
      }
    }
  }, [zoom, minState]);

  const handleSelectButton = () => {
    if (isSelecting && mapRef.current?.selectArea?.enabled?.()) {
      setSelect(false);
      mapRef.current?.selectArea?.disable?.();
    } else {
      setPanel(false);
      setSelect(true);
      mapRef.current?.selectArea?.enable?.();
    }
  };

  useEffect(() => {
    mapRef.current?.selectArea?.setShiftKey?.(false);
    mapRef.current?.selectArea?.setControlKey?.(false);

    mapRef.current.on("areaselected", (e) => {
      setSel([]);
      let selection: any[] = [];
      mapRef.current?.eachLayer((b) => {
        if (b instanceof L.Marker) {
          if (e.bounds.contains(b.getLatLng())) {
            selection.push(b);
            setPanel(true);
          }
        }
      });
      setGroupPop([]);
      setSel(selection);
      setSelect(false);
      mapRef.current?.selectArea?.disable?.();
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(selMarkers)) {
      selMarkers.forEach((s) => (s as any)._icon.classList.add("marker--selected"));
    } else {
      mapRef.current?.eachLayer((b) => {
        if (b instanceof L.Marker) {
          (b as any)._icon.classList.remove("marker--selected");
        }
      });
    }
  }, [selMarkers]);

  useEffect(() => {
    return () => {
      toastDel();
    };
  }, []);

  return (
    <SCont>
      <MemoizedMap />
      {markerPopContent != null
        ? createPortal(
            <SPopup>
              <PopContent
                data={markerPopContent.data as IMapMarker}
                openCheck={openCheck}
                setForm={setForm}
                cancelDeparture={cancelDeparture}
              />
            </SPopup>,
            markerPopContent.node,
          )
        : null}
      {minerPopContent != null
        ? createPortal(
            <MinerPopup data={minerPopContent.data as IMapMiner} />,
            minerPopContent.node,
          )
        : null}
      {depState?.visible || anoState?.visible ? (
        <>
          <LeftPanel
            isOpen={isPanelOpen && (!isEmpty(groupPopContent) || !isEmpty(selMarkers))}
            close={() => {
              setPanel(false);
              setGroupPop([]);
              clearSelection();
            }}
            group={groupPopContent}
            selection={selMarkers}
            openCheck={openCheck}
            setForm={setForm}
            cancelDeparture={cancelDeparture}
          />
          {!isDesktop() ? (
            <SimpleSearch
              onBtnClick={openCheck}
              onItemClick={handleSearchClick}
              list={departures?.filter((a) => a.lat && a.lng)}
              disabled={isEmpty(departures)}
            />
          ) : null}
        </>
      ) : null}

      <STools>
        {(depState?.visible || anoState?.visible) && isDesktop() ? (
          <SimpleSearch
            onBtnClick={openCheck}
            onItemClick={handleSearchClick}
            list={departures?.filter((a) => a.lat && a.lng)}
            disabled={isEmpty(departures)}
          />
        ) : null}
        <SToolBtn onClick={() => setLayers(true)}>
          <Icon.Layer />
          {isLayersOpen ? (
            <LayersControl
              layersState={reducerState}
              layersDispatch={dispatchReducer}
              mapInstance={mapRef.current}
              depInstance={depLayer.current}
              anoInstance={anoLayer.current}
              minPolyInstance={minPolyLayer.current}
              minMarkersInstance={minMarkersLayer.current}
              onClose={() => setLayers(false)}
              onSwitch={() => {
                setSel([]);
              }}
            />
          ) : null}
        </SToolBtn>
        <TableFilter
          className="tools__filter"
          dataLength={
            depState?.visible || anoState?.visible ? departures?.length ?? 0 : miners?.length ?? 0
          }
          isOpen={filterOpen}
          isOn={Object.values(reducerState).some((a) => a.visible)}
          defaultFilter={calcDefaultFilter(
            depState?.visible,
            anoState?.visible,
            isStorageFilterAvail,
            storageFilter,
          )}
          toogleShowFilter={filterToggle}
          onApplyFilter={isMinersPage || minState?.visible ? handleMiningFilter : handleDepFilter}
          scheme={filterItems}
          btnStyle={forceCss}
          isSchemeOnly={isMinersPage}
          isPending={depIsLoading || minIsLoading}
          slideIndex={410}
          isHideSwitch
        />
        {isDesktop() ? (
          !isMinersPage ? (
            <SToolBtn
              onClick={handleSelectButton}
              isOn={isSelecting && mapRef.current?.selectArea?.enabled?.()}
            >
              <Icon.Selection />
            </SToolBtn>
          ) : null
        ) : (
          <MinersList list={miners} mapInstance={mapRef.current} />
        )}
      </STools>
      <ZoomLocation mapInstance={mapRef.current} />
      {isTablet() ? (
        <SlideIn
          title="Назначить проверку"
          isOpen={isFormOpen}
          close={() => {
            setFormOpen(false);
            setForm(null);
          }}
          zIndex={402}
        >
          {isFormOpen ? (
            <FormDepartures
              form={form}
              devices={devices}
              onClose={clearSelection}
              onSuccess={departureSubmitSuccess}
            />
          ) : null}
        </SlideIn>
      ) : (
        <MTSModal
          open={isFormOpen}
          close={() => {
            setFormOpen(false);
            setForm(null);
          }}
          size="S"
        >
          <FormDepartures
            form={form}
            devices={devices}
            onClose={clearSelection}
            onSuccess={departureSubmitSuccess}
          />
        </MTSModal>
      )}
    </SCont>
  );
};
