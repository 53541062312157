import { IMngAbonentResponse } from "interface/management";
import { getPhoneWithSpaces } from "utils/formatters";
import { convertEmptyValue } from "../utils";

export const makeAbonentInfo = (dt: IMngAbonentResponse) => {
  const { comment, username, phone, email, sn, contract_num } = dt;

  return {
    contract_num: {
      title: "Номер договора",
      text: contract_num,
      isCanCopy: true,
    },
    username: {
      title: "ФИО",
      text: username,
      isCanCopy: true,
    },
    phone: {
      title: "Телефон",
      text: convertEmptyValue(getPhoneWithSpaces(phone)),
      isCanCopy: true,
    },
    email: {
      title: "Электронная почта",
      text: convertEmptyValue(email),
      isCanCopy: true,
    },
    comment: {
      title: "Причина отклонения заявки",
      text: comment,
    },
    sn: {
      title: "Серийный номер устройства",
      text: sn,
      isCanCopy: true,
    },
  };
};

export const makeActiveAbonentInfo = (dt: IMngAbonentResponse) => {
  const { username, phone, email, contract_num } = dt;
  return {
    contract_num: {
      title: "Номер договора",
      text: contract_num,
      isCanCopy: true,
    },
    username: {
      title: "ФИО",
      text: username,
      isCanCopy: true,
    },
    phone: {
      title: "Телефон",
      text: convertEmptyValue(getPhoneWithSpaces(phone)),
      isCanCopy: true,
    },
    email: {
      title: "Электронная почта",
      text: convertEmptyValue(email),
      isCanCopy: true,
    },
  };
};
