import { FC, Fragment } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useAddNewAbonentDevice } from "hooks/api/abonent";
import { MTSInput } from "components/mts-input";
import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { defaultValues, schema } from "./validation-schema";
import Icon from "components/icons";
import { toastInfoErr, toastInfoSucc } from "./const";
import { SAddNew, SBtns, SDelIcon, SInp, SSubtitle, STop } from "./styles";

type IProps = {
  userId: number;
  onCancel: () => void;
};

export const FormAddSn: FC<IProps> = ({ userId, onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "sn",
  });

  const watchSn = watch("sn");
  const mutation = useAddNewAbonentDevice();

  const onSubmit = async (submData) => {
    mutation.mutate(
      {
        user_id: userId,
        sn: submData.sn.map((a) => a.name),
      },
      {
        onSuccess: () => {
          toastInfoSucc();
          onCancel();
        },
        onError: () => {
          toastInfoErr();
          onCancel();
        },
      },
    );
  };

  return (
    <>
      <STop>
        <CusTypo variant="p1_medium" font="comp">
          Добавить устройство
        </CusTypo>
        <Spacer value="16px" />
        <SSubtitle variant="p4_regular">
          Абонент сможет просматривать данные полученные от этого устройства.
        </SSubtitle>
      </STop>
      <Spacer value="24px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <Fragment key={field.id}>
            <SInp>
              <MTSInput
                label="Серийный номер устройства"
                control={control}
                {...register(`sn.${index}.name`, { required: index === 0 })}
                errorMessage={errors?.sn?.[index]?.name.message}
              />
              {index !== 0 ? (
                <SDelIcon
                  onClick={() => remove(index)}
                  isError={!!errors?.sn?.[index]?.name.message}
                >
                  <Icon.Close sx={{ width: 20 }} />
                </SDelIcon>
              ) : null}
            </SInp>
            <Spacer value="4px" />
            {index === fields.length - 1 && (
              <SAddNew>
                <MTSButton
                  size="S"
                  variant="secondary"
                  onClick={() => {
                    append({
                      name: "",
                    });
                  }}
                  icon={<Icon.Plus sx={{ width: 14, height: 14 }} />}
                  disabled={index !== 0 && !watchSn[index].name.length}
                >
                  Новое устройство
                </MTSButton>
              </SAddNew>
            )}
          </Fragment>
        ))}
        <Spacer value="36px" />
        <SBtns>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isValid}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={onCancel}>
            Отмена
          </MTSButton>
        </SBtns>
      </form>
    </>
  );
};
