import { useContext } from "react";

import { ContextSettings } from "context";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Spacer } from "components/spacer/spacer.component";
import { Loader } from "components/loader/loader";
import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifyBlock } from "components/notification-block/notification-block";
import { MetersGrid } from "components/askue/MetersGrid/MetersGrid";
import { FormDownloadReport } from "components/forms/abonent-report/form-download-report";
import { AskueEventsTable } from "components/askue/askueEventsTable/askueEventsTable.component";
import { ROUTE_ASKUE_EVENTS } from "stream-constants/route-constants";
import { BaseLink } from "components/link/link";
import { useAskueAbonentEvents, useGetAbonentDeviceList } from "hooks/api/abonent";
import { composeBackendFilter, links_root } from "./const";
import { SBtn, SFormWrap, SSection } from "./styles";

export const AskueAbonentMain = () => {
  const { isMobile } = useContext(ContextSettings);
  const {
    data: deviceList,
    isLoading: isDevicesLoading,
    isSuccess: isDevicesSuccess,
    isError: isDevicesError,
  } = useGetAbonentDeviceList();
  const { data, fetchNextPage, isLoading, isSuccess } = useAskueAbonentEvents({
    ...composeBackendFilter([...[], { column: "period", value: [null, null] }]),
  });

  const eventsList = data?.pages.flatMap((p) => p.results) ?? [];

  return (
    <>
      <SSection>
        <Breadcrumbs links={links_root} />
        <div>
          <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            Устройства
          </CusTypo>
          <Spacer value="48px" />
        </div>
        {isDevicesLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : isDevicesSuccess && deviceList ? (
          <MetersGrid data={deviceList?.results} />
        ) : (
          (isDevicesError || deviceList?.results.length === 0) && (
            <NotifyBlock content={"Нет данных"} status="error" />
          )
        )}
        <Spacer value="64px" />
        <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
          Отчеты
        </CusTypo>
        <Spacer value="48px" />
        <SFormWrap>
          <FormDownloadReport devices={deviceList?.results ?? []} />
        </SFormWrap>
        <Spacer value="64px" />
        <>
          <CusTypo variant={!isMobile() ? "h2_medium" : "h4_medium"} font="wide">
            Последние обновления
          </CusTypo>
          <Spacer value="24px" />
          {isLoading ? (
            <div style={{ height: 100 }}>
              <Loader />
            </div>
          ) : isSuccess && eventsList.length ? (
            <>
              <AskueEventsTable
                rawData={eventsList ?? []}
                onEndReach={fetchNextPage}
                height={596}
              />
              <Spacer value="24px" />
              <SBtn>
                <BaseLink href={`/${ROUTE_ASKUE_EVENTS}`} size="S" text="Смотреть все события" />
              </SBtn>
            </>
          ) : (
            <NotifyBlock content={"Нет данных"} status="error" />
          )}
        </>
      </SSection>
    </>
  );
};
