import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    "& .MuiTypography-root": {
      color: theme.mtsColor.text.primaryLink.lightMode,
      textDecoration: "underline",
    },
  },
  startIcon: {
    marginRight: "8px",
    width: "24px",
    height: "24px",
  },
  endIcon: {
    marginLeft: "8px",
  },
  linkSizeS: {
    textAlign: "left",
    maxWidth: "439px",
  },
  linkSizeM: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.text.inverted.lightMode,
    },
  },
  linkSizeL: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.text.inverted.lightMode,
    },
  },
  linkGrey: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.grey.greyMedium,
    },
  },
  linkBlack: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.background.primary.darkMode,
    },
  },
  linkWhite: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.text.inverted.lightMode,
    },
  },
  linkDisabled: {
    "& .MuiTypography-root": {
      color: theme.mtsColor.text.inverted.lightMode,
    },
  },
}));

export default useStyles;
