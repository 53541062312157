import rgba from "polished/lib/color/rgba";
import styled from "@emotion/styled";
import { MTS_COMPACT, device } from "theme";

import { CusTypo } from "components/cusTypo/custom-typography";
import Icon from "components/icons";

export const SSection = styled.section`
  padding: 48px 20px 0;
  text-align: left;

  @media ${device.tabletPlus} {
    padding: 48px 32px 0 32px;
  }
`;

export const STitle = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
  grid-area: title;
`;

export const SItem = styled.div`
  width: 100%;
  max-width: inherit;

  &.searchField {
    width: 100%;

    @media ${device.tabletPlus} {
      max-width: 300px;
    }
  }

  @media ${device.tabletPlus} {
    max-width: fit-content;
  }
`;

export const SPos = styled.div`
  display: flex;
  align-items: center;
  & > .MuiTypography-root {
    margin-right: 16px;
  }

  & > div {
    flex: 1 1 100px;
  }
`;

export const SDash = styled.form`
  padding: 0 8px;
`;

const SRow = styled.section<{ isErr?: boolean }>`
  padding: 6px 24px;
  min-height: 44px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: ${(props) =>
    props.isErr
      ? rgba(props.theme.mtsColor.text.negative.lightMode, 0.08)
      : rgba(props.theme.mtsColor.text.positive.lightMode, 0.08)};
  border-radius: 8px;

  & > div > svg {
    display: block;
  }
`;

export const SMsg = styled(CusTypo)<{ isErr?: boolean }>`
  font-family: ${MTS_COMPACT}, sans-serif;
  color: ${(props) =>
    props.isErr
      ? props.theme.mtsColor.text.negative.lightMode
      : props.theme.mtsColor.text.positive.lightMode};
`;

export const SSumProblem = ({ isErr = false }) => {
  return (
    <SRow isErr={isErr}>
      <SMsg variant="c1_regular" isErr={isErr}>
        {isErr
          ? "Нет данных для отображения общей информации"
          : "Ваши приборы учета под защитой. На данный момент аномалии не обнаружены, список анализируется и формируется."}
      </SMsg>
      <div style={{ margin: "0 0 0 auto" }}>
        {isErr ? (
          <Icon.ErrorShaped sx={{ width: 18, height: 18 }} />
        ) : (
          <Icon.CheckShaped sx={{ width: 18, height: 18 }} />
        )}
      </div>
    </SRow>
  );
};

export const SCard = styled.div`
  padding: 12px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 1px 5px 22px 0px ${(props) => props.theme.mtsColor.base.rockBlueTransparent};

  @media ${device.tabletPlus} {
    padding: 32px;
  }
`;

export const SSubtitle = styled(CusTypo)`
  color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
`;

export const SFiles = styled.div`
  & > a {
    &:not(:last-of-type) {
      padding: 12px 0;
      border-bottom: 1px solid ${(props) => props.theme.mtsColor.background.secondary.lightMode};
    }

    &:last-of-type {
      padding-top: 12px;
    }
  }

  @media ${device.tabletPlus} {
    padding: 0;

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    & > a {
      border-bottom: none !important;
    }
  }
`;

export const SFile = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;

  & > svg {
    fill: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }

  & > span:first-of-type {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
  }

  & > span:last-of-type {
    white-space: nowrap;
    color: ${(props) => props.theme.mtsColor.text.tertiary.lightMode};
  }
`;

export const SLink = styled.a`
  text-decoration: none;
  color: ${(props) => props.theme.mtsColor.text.primaryLink.lightMode};
`;

export const SArticle = styled.div<{ isFullTitle?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "title btns"
    "fields fields";
  gap: 24px;
  padding-bottom: 12px;

  & > span {
    width: ${(props) => (props.isFullTitle ? "inherit" : "min-content")};
  }

  @media ${device.tabletPlus} {
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "title fields btns";
    grid-template-rows: 1fr;
  }
`;

export const SFields = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: 12px;
  width: 100%;
  grid-area: fields;

  @media ${device.tabletPlus} {
    flex-direction: row;
  }

  @media ${device.desktopPlus} {
    gap: 16px;
    flex-direction: inherit;

    & .MuiButton-root {
      white-space: nowrap;
      flex: 0 0 auto;
      height: 32px;
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  grid-area: btns;
`;

export const SFormWrap = styled.div`
  padding: 24px;
  border-radius: 8px;
  background: ${(props) => props.theme.mtsColor.background.secondary.lightMode};
`;

export const SBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SContainer = styled.section`
  width: 100%;
  max-width: 1164px;
  padding: 64px 20px;
`;

export const SNotify = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
