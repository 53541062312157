import { Link } from "react-router-dom";
import cn from "classnames";
import { CusTypo } from "components/cusTypo/custom-typography";
import useStyles from "./styles";

interface BaseLinkProps {
  href: string;
  size: "S" | "M" | "L";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  text?: string;
  variant?: "grey" | "black" | "white";
  disabled?: boolean;
}

export const BaseLink = (props: BaseLinkProps) => {
  const { href, size, startIcon, endIcon, text, variant, disabled } = props;
  const classes = useStyles();

  const getTypographyVariantBySize = (val: string) => {
    switch (val) {
      case "S": {
        return "p4_regular";
      }
      case "M": {
        return "p3_regular";
      }
      case "L": {
        return "p3_regular";
      }
      default:
        return "p4_regular";
    }
  };
  const linkClasses = cn({
    [classes.link]: true,
    [classes.linkSizeS]: size === "S",
    [classes.linkSizeM]: size === "M",
    [classes.linkSizeL]: size === "L",
    [classes.linkDisabled]: disabled,
    [classes.linkGrey]: !disabled && variant === "grey",
    [classes.linkBlack]: !disabled && variant === "black",
    [classes.linkWhite]: !disabled && variant === "white",
  });

  return (
    <Link to={href} className={linkClasses}>
      {startIcon && <span className={classes.startIcon}>{startIcon}</span>}
      <CusTypo variant={getTypographyVariantBySize(size)} font="sans">
        {text}
      </CusTypo>
      {endIcon && <span className={classes.endIcon}>{endIcon}</span>}
    </Link>
  );
};
