import { FC, Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import { MTSButton } from "components/mts-button/mts-button";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IMngAbonentResponse } from "interface/management";
import { useUpdateStatusApplication } from "hooks/api/management";
import { MTSAutocomplete } from "components/mts-autocomplete/mts-autocomplete";
import { EAbonentStatus } from "components/management-abonent/const";
import { schemaUpdateStatus } from "./validation-schema";
import { MTSInput } from "components/mts-input";
import { Toast } from "components/toast/toast";
import { HARDCODE_REJECT_MES } from "./const";
import { SBtns, SFormField, SSubtitle, STop } from "./styles";

type IProps = {
  data: IMngAbonentResponse;
  onCancel: () => void;
};

export const FormRejectApplication: FC<IProps> = ({ data, onCancel }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<any>({
    defaultValues: {},
    resolver: yupResolver(schemaUpdateStatus),
    mode: "onChange",
    shouldFocusError: false,
    reValidateMode: "onChange",
  });

  const watchReason = watch("reason");
  const commentRules =
    watchReason === "Другое"
      ? {
          required: "Поле не заполнено",
          minLength: { value: 5, message: "Комментарий должен быть осмысленным" },
        }
      : {};

  const mutation = useUpdateStatusApplication();

  const onSubmit = (submData) => {
    const commentRes = submData.reason === "Другое" ? submData.comment : submData.reason;

    mutation.mutate(
      {
        status: EAbonentStatus["Заявка отклонена"],
        user_id: data.user_id,
        comment: commentRes,
      },
      {
        onSuccess: () => {
          toastSucc();
          onCancel();
        },
        onError: () => {
          toastErr();
          onCancel();
        },
      },
    );
  };

  return (
    <>
      <STop>
        <CusTypo variant="p1_medium" font="comp">
          Отклонить заявку?
        </CusTypo>
        <Spacer value="8px" />
        <SSubtitle variant="p4_regular">
          Абоненту необходимо будет снова заполнить заявку на подключение устройства.
        </SSubtitle>
        <Spacer value="24px" />
      </STop>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SFormField>
          <MTSAutocomplete
            label="Укажите причину отклонения заявки"
            placeholder=""
            options={HARDCODE_REJECT_MES}
            control={control}
            size="M"
            {...register("reason")}
            errorMessage={errors?.reason?.message}
          />
        </SFormField>
        {watchReason === "Другое" ? (
          <SFormField>
            <MTSInput
              label="Опишите причину"
              placeholder="Оставьте комментарий"
              type="textarea"
              {...register("comment", watchReason === "Другое" ? commentRules : {})}
              control={control}
              errorMessage={errors?.comment?.message}
            />
          </SFormField>
        ) : null}
        <SBtns>
          <MTSButton
            size="M"
            variant="primary"
            type="submit"
            onClick={() => undefined}
            disabled={!isValid}
          >
            Подтвердить
          </MTSButton>
          <MTSButton size="M" variant="secondary" onClick={onCancel}>
            Отмена
          </MTSButton>
        </SBtns>
      </form>
    </>
  );
};

export const toastSucc = () => {
  toast(<Toast title="Заявка отклонена" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};

export const toastErr = () => {
  toast(<Toast title="Заявка не отклонена" isError />, {
    progress: undefined,
    autoClose: 1500,
    hideProgressBar: true,
  });
};
