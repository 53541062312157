import { toast } from "react-toastify";
import { OverridableStringUnion } from "@mui/types";
import { TypographyPropsVariantOverrides } from "@mui/material/Typography/Typography";
import { Variant } from "@mui/material/styles/createTypography";

import Icon from "components/icons";
import { Toast } from "components/toast/toast";
import { CusTypo } from "components/cusTypo/custom-typography";

export const makeCopyText = (e: React.MouseEvent<HTMLElement>, value: string) => {
  e.stopPropagation();
  navigator.clipboard.writeText(value).then(() => {
    toast(<Toast title="Информация скопирована" />, {
      progress: undefined,
      autoClose: 2000,
      hideProgressBar: true,
    });
  });
};

interface IProp {
  text: string | number;
  variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
  font?: "sans" | "wide" | "comp";
  ref?: React.RefObject<HTMLElement>;
}

export const CopyToClipboard: React.FC<IProp> = ({ text, variant = "c1_medium", font, ref }) => {
  return (
    <CusTypo
      variant={variant}
      onClick={(e) => makeCopyText(e, String(text))}
      styles={{ cursor: "pointer" }}
      font={font}
      ref={ref}
    >
      {text}
      <Icon.Copy sx={{ width: 12, height: 12, marginLeft: "4px" }} />
    </CusTypo>
  );
};
