import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { SerializedStyles } from "@emotion/react";
import { isEmpty, isEqual } from "lodash";

import Icon from "components/icons";
import { MTSButton } from "components/mts-button/mts-button";
import { declWords } from "utils/declensionWords";
import { Switch } from "components/switch/switch.component";
import { Spacer } from "components/spacer/spacer.component";
import { ChxRenderer } from "./components/checkbox-renderer";
import { SlideIn } from "../slideIn/slideIn.component";
import { ContextSettings } from "context/context-settings";
import { useDevicesPoList, useDevicesPriemList } from "hooks/api/anomalies";
import { RadioRenderer } from "./components/radio-renderer";
import { getFilterArray } from "../utils";
import { CusTypo } from "components/cusTypo/custom-typography";
import { PO_COLUMN, RES_COLUMN } from "pages/anomalies/const";
import { SBtns, SFltBtn, SResult, SWrap } from "./styles";

export type IKek = { [key: string]: string[] | null };
export type IFilter = { column: string; value: string[] }[];
export type IDropElem = {
  id: number;
  name: string | null;
  friendlyName?: string;
};
export type IItem = {
  [key: string]: IDropElem[] | undefined;
};

interface IProp {
  dataLength: number;
  isOpen: boolean;
  onApplyFilter: ({ filtersArr }) => void;
  onResetFilter?: () => void;
  defaultFilter: IKek;
  isOn?: boolean;
  toggleSwitch?: (chk: boolean) => void; // если не передано, то нельзя вкл\выкл свичом
  toogleShowFilter: (val: boolean) => void;
  className?: string;
  scheme: {
    checkbox: IItem;
    radio: IItem;
  };
  btnStyle?: SerializedStyles;
  isSchemeOnly?: boolean;
  isPending?: boolean;
  slideIndex?: number;
  isHideSwitch?: boolean;
}

export const TableFilter: React.FC<IProp> = ({
  dataLength,
  isOpen,
  onApplyFilter,
  defaultFilter,
  isOn = false,
  toggleSwitch,
  onResetFilter,
  toogleShowFilter,
  className,
  scheme,
  btnStyle,
  isSchemeOnly = false,
  isPending = false,
  isHideSwitch = false,
  slideIndex,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const [dataRes, setDataRes] = useState<IKek>(() => defaultFilter);

  const closeFilter = () => {
    toogleShowFilter(false);
  };

  const openFilter = () => {
    toogleShowFilter(true);
  };

  const checkAll = (column: string, list: any[], makeReset?: boolean) => {
    if (makeReset) {
      setDataRes((s) => {
        return {
          ...s,
          [column]: [],
        };
      });
    } else {
      setDataRes((s) => {
        const vals = list.map((a) => a.name);
        return {
          ...s,
          [column]: vals,
        };
      });
    }
  };

  const toggleChbx = (column: string, name: string) => {
    setDataRes((s) => {
      const target = s[column] ?? [];
      const arr = target?.includes(name) ? target?.filter((a) => a !== name) : [...target, name];

      return {
        ...s,
        [column]: arr,
      };
    });
  };

  const toggleRadio = (column: string, name: string) => {
    setDataRes((s) => {
      return {
        ...s,
        [column]: name != null ? [name] : null,
      };
    });
  };

  const handleApplyFilter = (filters) => {
    onApplyFilter({
      filtersArr: filters,
    });
  };

  const handleToggleSwitch = (e) => {
    toggleSwitch?.(e);
    e ? handleApplyFilter(getFilterArray(dataRes)) : handleApplyFilter([]);
  };

  const handleResetFilter = () => {
    setDataRes((s) => {
      const keys = Object.keys(s);
      return keys.reduce((sum, cur) => ({ ...sum, [cur]: [] }), {}) as any;
    });
    handleApplyFilter([]);
    onResetFilter?.();
  };

  const { data: poList, isLoading: isPoLoading } = useDevicesPoList(isOpen && !isSchemeOnly);
  const { data: resList, mutate, isLoading: isPriemLoading } = useDevicesPriemList();

  const innerItems = useMemo(
    () => ({
      [PO_COLUMN]: poList?.po
        ?.filter((a) => a != null)
        .map((item: string | null, index: number) => {
          return {
            id: index,
            name: item,
          };
        }),
      [RES_COLUMN]: resList?.priem?.map((item: string, index: number) => {
        return {
          id: index,
          name: item,
        };
      }),
    }),
    [resList, poList],
  );

  const finalItems = {
    checkbox: {
      ...(isSchemeOnly ? {} : innerItems),
      ...scheme.checkbox,
    },
    radio: {
      ...scheme.radio,
    },
  };

  const poListRequestParam = dataRes[PO_COLUMN];

  const memoFilterPoVal = useRef<any>([]);

  useEffect(() => {
    if (isOpen && !isSchemeOnly) {
      const filterPoVal = poListRequestParam ?? [];

      if (!isEqual(filterPoVal, memoFilterPoVal.current)) {
        mutate(
          { list_po: filterPoVal },
          {
            onSuccess: () => {
              let futureFilter = {
                ...dataRes,
                [RES_COLUMN]: [],
              };

              setDataRes(futureFilter);
              memoFilterPoVal.current = filterPoVal;
            },
          },
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isSchemeOnly, mutate, poListRequestParam]);

  useEffect(() => {
    if (isOpen && !isSchemeOnly) {
      mutate({ list_po: poListRequestParam ?? [] });
      memoFilterPoVal.current = poListRequestParam;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, isSchemeOnly]);

  useEffect(() => {
    handleApplyFilter(getFilterArray(dataRes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRes]);

  return (
    <>
      <SFltBtn
        withValue={Object.values(dataRes)?.some((a) => !isEmpty(a)) && isOn}
        className={className ?? ""}
        onClick={openFilter}
        btnStyle={btnStyle}
      >
        <Icon.Filter />
      </SFltBtn>
      <SlideIn
        isOpen={isOpen}
        close={closeFilter}
        zIndex={slideIndex}
        headerChildren={
          <>
            {!isHideSwitch ? (
              <Switch
                id="header__switch"
                setChecked={toggleSwitch ? handleToggleSwitch : () => undefined}
                checked={isOn}
                className="header__switch"
                disabled={!toggleSwitch}
              />
            ) : null}

            <CusTypo variant={!isDesktop() ? "h4_medium" : "h3_medium"} font="comp">
              Фильтр
            </CusTypo>
          </>
        }
      >
        <SWrap isDisabled={!isOn}>
          {Object.keys(finalItems["radio"])?.map((radio) => (
            <RadioRenderer
              key={radio}
              filterKey={radio}
              toggle={toggleRadio}
              currFilters={dataRes}
              targetArray={finalItems["radio"][radio]}
            />
          ))}
          {Object.keys(finalItems["checkbox"])?.map((check) => {
            const trgKey = finalItems["checkbox"];
            return (
              <ChxRenderer
                key={check}
                targetArray={trgKey[check]}
                filterKey={check}
                isOn={isOn}
                isAllChecked={(trgKey[check] ?? []).length === dataRes[check]?.length}
                toggleAll={checkAll}
                toggleSingle={toggleChbx}
                currFilters={dataRes}
                isLoading={isPriemLoading || isPoLoading}
              />
            );
          })}
        </SWrap>
        <SBtns>
          <MTSButton
            size="M"
            variant="dark_blue"
            onClick={handleResetFilter}
            disabled={!isOn}
            loading={isPending}
          >
            Сбросить все
          </MTSButton>
        </SBtns>
        <Spacer value="24px" />
        <SResult>
          <CusTypo variant="c1_regular">
            Найдено {declWords(dataLength, ["результат", "результата", "результатов"])}
          </CusTypo>
        </SResult>
      </SlideIn>
    </>
  );
};
