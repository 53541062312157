import { useRef, useState, useLayoutEffect, useContext } from "react";
import { AccordionDetails, AccordionSummary } from "@mui/material";

import { convertRoleToCyr, hasRoleAccess } from "components/forms/user/validation-schema";
import { ContextSettings } from "context/context-settings";
import { convertEmptyValue } from "components/card-info/utils";
import Icon from "components/icons";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { CusTypo } from "components/cusTypo/custom-typography";
import { AbonentAccordionDetails } from "components/card-info/abonent/abonent.component";
import { PopupAction } from "components/popup-action/popup-action";
import { MTSModal } from "components/mts-modal/mts-modal";
import { FormDeleteSn } from "components/forms/abonent-management/form-del-sn";
import { FormEditAbonent } from "components/forms/abonent-management/form-edit-abonent";
import { FormDeleteAbonent } from "components/forms/abonent-management/form-del-abonent";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { FormAddSn } from "components/forms/abonent-management/form-add-sn";
import { HEIGHT_EXP } from "components/table/styles";
import { SAccordion, SRow, SWrap } from "./styles";

const MAGIC_HEIGHT = 255;
const FAULT = 40;

export const Info = ({ rowData }) => {
  const { roles } = useContext(ContextSettings);
  const isCanSeeTenant = hasRoleAccess(roles, rowData.roles);

  return (
    <CusTypo variant="p4_regular" styles={{ textAlign: "left" }}>
      {isCanSeeTenant ? (
        <CusTypo variant="p4_medium">{`${rowData.tenant?.title ?? EMPTY_CELL_VALUE} / `}</CusTypo>
      ) : null}
      {rowData.roles?.map((a) => convertRoleToCyr(a.name)).join(", ")}
    </CusTypo>
  );
};

export const Expandable = ({ value, row, isOpen, isCanCopy }) => {
  const [parent, setParent] = useState(900);
  const [heightInnerCard, setHeightInnerCard] = useState(0);

  const refOne = useRef<HTMLDivElement>(null);
  const refTwo = useRef<any>(null);

  const calcHeightInner = (val: number) => setHeightInnerCard(val);

  useLayoutEffect(() => {
    if (refOne?.current) {
      const width = refOne?.current?.closest?.(".tbody_tr")?.clientWidth ?? 900;
      setParent(width);
    }
  }, []);

  useLayoutEffect(() => {
    if (refTwo?.current) {
      const elem = refTwo?.current;
      const measured = elem?.getBoundingClientRect().height;
      const totalHeight = measured < MAGIC_HEIGHT ? measured + FAULT : measured;
      if (isOpen) {
        elem.closest(".tbody_tr").style.height =
          heightInnerCard > totalHeight ? `${heightInnerCard}px` : `${totalHeight + HEIGHT_EXP}px`;
      } else {
        elem.closest(".tbody_tr").style.height = `${HEIGHT_EXP}px`;
      }
    }
  }, [isOpen, heightInnerCard]);

  return (
    <>
      <SRow>
        {row.original.isResponsible ? <Icon.Crown /> : null}

        {isCanCopy ? (
          <CopyToClipboard text={convertEmptyValue(value)} variant="p4_medium" ref={refOne} />
        ) : (
          <CusTypo variant="p4_medium" ref={refOne}>
            {convertEmptyValue(value)}
          </CusTypo>
        )}
      </SRow>
      <SAccordion
        id={row.original.user_id}
        aria-controls={`panel${row.original.user_id}-content`}
        expanded={isOpen}
        TransitionProps={{
          unmountOnExit: true,
        }}
        style={{
          width: parent,
        }}
        ref={refTwo}
      >
        <AccordionSummary
          style={{
            minHeight: 0,
            height: 0,
          }}
        />
        <AccordionDetails>
          <AbonentAccordionDetails data={row.original} calcHeightInner={calcHeightInner} />
        </AccordionDetails>
      </SAccordion>
    </>
  );
};

export const AbonentDeskAction = ({ row }) => {
  const { user_id } = row.original;
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalAddDevice, setShowModalAddDevice] = useState(false);

  const toggleShowModalDelete = () => setShowModalDelete(!showModalDelete);
  const toggleShowModalEdit = () => setShowModalEdit(!showModalEdit);
  const toggleShowModalAddDevice = () => setShowModalAddDevice(!showModalAddDevice);

  const editFunc = {
    title: "Редактировать данные",
    onClick: toggleShowModalEdit,
  };

  const delFunc = {
    title: "Удалить",
    onClick: toggleShowModalDelete,
  };

  const addFunc = {
    title: "Добавить доступное устройсво",
    onClick: toggleShowModalAddDevice,
  };

  const dataPopup = [editFunc, addFunc, delFunc];

  return (
    <div>
      <PopupAction items={dataPopup} view="light" />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteAbonent onCancel={toggleShowModalDelete} id={user_id} />
      </MTSModal>
      <MTSModal open={showModalEdit} close={toggleShowModalEdit}>
        <FormEditAbonent onCancel={toggleShowModalEdit} data={row.original} />
      </MTSModal>
      <MTSModal open={showModalAddDevice} close={toggleShowModalAddDevice}>
        <FormAddSn userId={user_id} onCancel={toggleShowModalAddDevice} />
      </MTSModal>
    </div>
  );
};

export const AbonentSnDeskAction = ({ row, user_id }) => {
  const { sn } = row.original;
  const [showModalDelete, setShowModalDelete] = useState(false);

  const dataDel = {
    sn: Number(sn),
    user_id: user_id,
  };

  const toggleShowModalDelete = () => {
    setShowModalDelete(!showModalDelete);
  };

  return (
    <SWrap>
      <Icon.Close
        className="icon"
        sx={{ width: 16, height: 16, cursor: "pointer" }}
        onClick={(e) => {
          e.stopPropagation();
          toggleShowModalDelete();
        }}
      />
      <MTSModal open={showModalDelete} close={toggleShowModalDelete}>
        <FormDeleteSn data={dataDel} onCancel={toggleShowModalDelete} />
      </MTSModal>
    </SWrap>
  );
};
