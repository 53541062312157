import styled from "@emotion/styled";
import { device } from "theme";

export const SPage = styled.div`
  width: 100%;

  & > article {
    & > ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        white-space: nowrap;
        padding: 8px 12px;
        background-color: #f9f9fb;

        &:not(:last-of-type) {
          margin-bottom: 2px;
        }

        & > span {
          &:first-of-type {
            text-align: left;
            width: 120px;
            flex: 0 0 120px;
            color: ${(props) => props.theme.mtsColor.text.secondary.lightMode};
          }

          &:last-of-type {
            flex: 1 1 auto;
          }

          &:not(:first-of-type) {
            white-space: normal;
            text-align: right;
          }
        }
      }
    }
  }
`;

export const SCont = styled.div`
  padding: 24px 8px;
  cursor: default;
`;

export const STabs = styled.ul`
  display: flex;
`;

export const STabItem = styled.li<{ isActive?: boolean }>`
  margin-right: 32px;
  padding-bottom: 8px;
  border-bottom: 4px solid transparent;
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    `
		border-bottom: 4px solid ${props.theme.mtsColor.red};
	`}
`;

export const SSpecLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  background-color: #ffffff;

  &:nth-of-type(2n) {
    background-color: #f9f9fb;
  }

  & > span {
    display: flex;
    align-items: center;

    & span {
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
`;

export const SBtns = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;

  @media ${device.tabletPlus} {
    flex-direction: inherit;
    gap: 24px;
  }

  & > button {
    min-width: 247px;
  }
`;

export const SBtnsMob = styled.div`
  display: flex;
  gap: 24px;
  justify-content: end;
  padding: 0 20px 8px;

  & > button {
    min-width: 247px;
  }
`;
