import * as yup from "yup";
import validationMethod from "../common-validation";

const REQUIRED = "Поле не заполнено";
const INCORRECT = "Некорректно заполнено поле";

export const defaultValues = {
  tenant: "",
  username: "",
  contract_num: "",
  sn: [{ name: null }],
};

export const schema = yup.object().shape({
  tenant: yup
    .mixed()
    .required()
    .test("requiredOrganizationName", REQUIRED, (v) => v && v !== ""),
  username: yup
    .mixed()
    .test("requiredUserName", REQUIRED, validationMethod.requiredUserName)
    .test("correctUserName", INCORRECT, validationMethod.correctUserName),
  contract_num: yup.string().required(REQUIRED),
  sn: yup.array().of(
    yup.object().shape({
      name: yup.string().required(REQUIRED),
    }),
  ),
});
