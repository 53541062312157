import { format } from "date-fns";
import { MTSStatus } from "components/mts-status/mts-status";
import { IMngAbonentResponse } from "interface/management";
import { AbonentDeskAction, AbonentSnDeskAction, Expandable } from "./subComponents";
import { CusTypo } from "components/cusTypo/custom-typography";
import { IAskue } from "interface/askue";
import { CopyToClipboard } from "components/copyToClipboard/copyToClipboard.component";
import { EMPTY_CELL_VALUE } from "stream-constants/keys-and-constants";
import { EAbonentStatus } from "./const";

export const constructTableData = (draft: IMngAbonentResponse[]) =>
  draft?.map((d) => {
    const { user_id } = d;
    return {
      ...d,
      id: user_id,
    };
  });

export const constructTableDataSn = (draft: IAskue[]) =>
  draft?.map((d) => {
    return {
      ...d,
    };
  });

interface IProp {
  columns: any[];
  active: { [key: string]: boolean };
}

export const constructColumns = (data: IProp) => {
  return data.columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "contract_num"
        ? {
            Cell: ({ value, row }) => (
              <>
                <Expandable
                  value={value}
                  row={row}
                  isOpen={data.active[row.original.user_id] ?? false}
                  isCanCopy
                />
              </>
            ),
          }
        : singleColumn.accessor === "username"
        ? {
            Cell: ({ value }) => <CusTypo variant="c1_regular">{value}</CusTypo>,
          }
        : singleColumn.accessor === "created_time"
        ? {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular">{format(new Date(value ?? 0), `dd.MM.yyyy`)}</CusTypo>
            ),
          }
        : singleColumn.accessor === "accepted"
        ? { Cell: ({ value }) => (value ? MTSStatus(EAbonentStatus[value]) : null) }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <AbonentDeskAction row={row} />,
          }
        : { Cell: ({ value }) => null }),
    };
  });
};

export const constructColumnsSn = (data: IProp, savedId: number) => {
  return data.columns.map((singleColumn) => {
    return {
      ...singleColumn,
      ...(singleColumn.accessor === "sn"
        ? {
            Cell: ({ value, row }) => (
              <CopyToClipboard text={value ?? EMPTY_CELL_VALUE} variant="p4_medium" font="comp" />
            ),
          }
        : singleColumn.accessor === "action"
        ? {
            Cell: ({ row }) => <AbonentSnDeskAction row={row} user_id={savedId} />,
          }
        : {
            Cell: ({ value }) => (
              <CusTypo variant="c1_regular">{value ?? EMPTY_CELL_VALUE}</CusTypo>
            ),
          }),
    };
  });
};
