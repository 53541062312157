import Icon from "components/icons";
import { SWrap } from "./styles";
import { CusTypo } from "components/cusTypo/custom-typography";
import { Spacer } from "components/spacer/spacer.component";

interface IProps {
  title: string;
  subtitle?: string | null;
  isError?: boolean | null;
}

export const NotifySection = ({ title, subtitle, isError = false }: IProps) => {
  return (
    <SWrap>
      <div style={{ margin: "0 0 0 auto" }}>
        {isError ? (
          <Icon.ErrorShaped sx={{ width: 80, height: 80 }} />
        ) : (
          <Icon.SuccessShaped sx={{ width: 80, height: 80 }} />
        )}
      </div>
      <Spacer value="24px" />
      <CusTypo variant="h2_bold" font="wide">
        {title}
      </CusTypo>
      <Spacer value="24px" />
      <CusTypo variant="p4_regular" styles={{ whiteSpace: "pre-wrap" }}>
        {subtitle}
      </CusTypo>
    </SWrap>
  );
};
