import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const AskueCard = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="146"
      height="178"
      viewBox="0 0 146 178"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_8293_6111)">
        <mask
          id="mask0_8293_6111"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="146"
          height="178"
        >
          <path
            d="M138 0H8C3.58172 0 0 3.58172 0 8V170C0 174.418 3.58172 178 8 178H138C142.418 178 146 174.418 146 170V8C146 3.58172 142.418 0 138 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_8293_6111)">
          <g opacity="0.1">
            <mask
              id="mask1_8293_6111"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="36"
              y="75"
              width="110"
              height="105"
            >
              <path d="M145.88 75H36V179.71H145.88V75Z" fill="white" />
            </mask>
            <g mask="url(#mask1_8293_6111)">
              <path
                d="M146 144.52C121.17 144.52 91.2701 144.52 66.4401 144.52C63.7401 144.64 61.2401 142.34 61.1801 139.63C61.1501 132.8 61.1701 125.97 61.1701 119.15C61.1401 116.92 62.7301 114.82 64.8701 114.23C65.3701 114.08 65.8901 114.04 66.4101 114.02C91.3601 114.02 120.92 113.96 145.87 114M71.4201 124.26C71.4201 127.59 71.4201 130.93 71.4201 134.26C74.2301 134.26 77.0401 134.26 79.8601 134.26C79.8601 130.93 79.8601 127.6 79.8601 124.27C77.0501 124.27 74.2301 124.27 71.4201 124.27V124.26ZM90.1101 124.27C90.1201 127.6 90.0901 130.94 90.1301 134.27C92.9401 134.25 95.7501 134.27 98.5501 134.27C98.5501 130.94 98.5501 127.61 98.5501 124.27C95.7301 124.27 92.9201 124.27 90.1101 124.27ZM108.8 124.26C108.8 127.59 108.8 130.93 108.8 134.26C111.61 134.26 114.41 134.26 117.22 134.26C117.26 130.93 117.22 127.59 117.24 124.26C114.42 124.25 111.61 124.26 108.8 124.26ZM127.49 124.27C127.49 127.6 127.49 130.93 127.49 134.26C130.3 134.26 133.12 134.26 135.93 134.26C135.93 130.93 135.93 127.6 135.93 124.26C133.12 124.26 130.3 124.26 127.49 124.26V124.27Z"
                fill="#979FA8"
              />
              <path
                d="M111.54 151.68C114.47 151.07 117.53 153.43 117.75 156.4C118.1 159.25 115.71 162.04 112.85 162.18C110.08 162.44 107.41 160.14 107.21 157.38C106.9 154.73 108.94 152.12 111.55 151.68H111.54Z"
                fill="#979FA8"
              />
              <path
                d="M127.99 151.81C130.91 150.86 134.25 153.01 134.69 156.02C135.27 158.93 132.91 161.96 129.96 162.17C127.17 162.5 124.43 160.23 124.2 157.44C123.89 154.97 125.61 152.49 127.98 151.81H127.99Z"
                fill="#979FA8"
              />
              <path
                d="M78.1702 151.96C79.1202 151.54 80.1503 151.65 81.1503 151.64C86.2603 151.64 91.3703 151.64 96.4703 151.64C98.3903 151.57 100.26 152.97 100.88 154.94C101.71 157.23 100.63 160.04 98.6002 161.08C97.5703 161.67 96.3703 161.64 95.2403 161.63C90.1203 161.61 85.0003 161.63 79.8803 161.62C77.7803 161.67 75.7803 160.02 75.3103 157.8C74.7103 155.43 76.0503 152.78 78.1702 151.96Z"
                fill="#979FA8"
              />
              <path
                d="M47.1376 180H59.9248C52.7929 171.577 48.1019 161.097 46.7459 150.137C45.3094 138.885 47.2682 127.223 52.3911 117.095C57.8455 106.224 66.8257 97.159 77.6743 91.6236C87.3978 86.5996 98.5778 84.4837 109.467 85.5366C121.41 86.6197 132.921 91.6336 141.922 99.5156C143.348 100.759 144.704 102.073 146 103.447V89.5779C143.428 87.5523 140.726 85.677 137.884 84.0425C127.236 77.8753 114.891 74.7365 102.596 75.0173C89.0652 75.258 75.6552 79.6803 64.646 87.5422C53.928 95.1334 45.4802 105.873 40.6888 118.087C35.4754 131.224 34.6015 146.005 38.1072 159.693C39.9354 166.924 43.0393 173.783 47.1376 180Z"
                fill="#979FA8"
              />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_8293_6111">
          <rect width="146" height="178" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default AskueCard;
