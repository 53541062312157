import { useMutation, useQuery, useInfiniteQuery } from "react-query";
import { queryClient as instance } from "react-query-wrapper";

import {
  IAskueAbonReportRequest,
  IAskueChartReq,
  IAskueChartRes,
  IAskueEnergyReq,
  IAskueEnergyRes,
  IAskueEventRequest,
  IAskueEventResponse,
  IAskueListResponse,
  IAskueManagementResponse,
  IAskueMeterNames,
  IAskueMngUpdStatusRequest,
  IAskueReleLogRes,
  IAskueRequest,
  IAskueResponse,
  IAskueServer,
  IAskueUpload,
} from "interface/askue";
import {
  askueGetAbonReportXlsx,
  askueGetDeviceListXlsx,
  askueUploadStatement,
  getAskue,
  getAskueList,
  getAskueReleLog,
  getAskueServer,
  getMetersName,
} from "services/askue";
import {
  askueMngUpdateStatus,
  getAskueEnergyChart,
  getEnergyAskue,
  getManagmentAskue,
} from "services/management-askue";
import { getAskueEvents } from "services/askue";
import { Keys, query_objects_limit } from "./const";

export function useGetAskueList(filters?: IAskueRequest | null) {
  return useInfiniteQuery<any, any, IAskueListResponse>(
    [Keys.ASKUE, "devices", filters],
    ({ pageParam = 1 }) =>
      getAskueList({ ...filters, page_num: pageParam, obj_cnt: query_objects_limit }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.offset >= lastPage.count
          ? undefined
          : lastPage.offset / lastPage?.limit + 1;
      },
      staleTime: 3000,
      cacheTime: 0,
      retryDelay: (attemptIndex) => Math.min(7500 * 2 ** attemptIndex, 30000),
    },
  );
}

export function useAskueUploadStatement() {
  return useMutation<any, any, IAskueUpload>((a) => askueUploadStatement(a), {
    mutationKey: [Keys.ASKUE, "statement"],
  });
}

export function useGetAskue() {
  return useQuery<any, { description?: string }, IAskueResponse>(
    [Keys.ASKUE, "info"],
    () => getAskue(),
    {
      retryDelay: (attemptIndex) => Math.min(7500 * 2 ** attemptIndex, 30000),
    },
  );
}

export function useAskueServer() {
  return useQuery<any, { description?: string }, IAskueServer>([Keys.ASKUE, "server"], () =>
    getAskueServer(),
  );
}

export function useManagementAskue() {
  return useQuery<any, any, IAskueManagementResponse[]>([Keys.ASKUE, "management"], () =>
    getManagmentAskue(),
  );
}

export function useAskueMngUpdateStatus() {
  return useMutation<any, any, IAskueMngUpdStatusRequest>((a) => askueMngUpdateStatus(a));
}

export const invalidateManagementAskue = async () => {
  await instance.invalidateQueries([Keys.ASKUE, "management"], { exact: true });
};

export function useAskueEvents(filters?: IAskueEventRequest | null, sortBy?: any) {
  return useInfiniteQuery<any, { description?: string }, IAskueEventResponse>(
    [Keys.ASKUE, "events", filters, sortBy],
    ({ pageParam = 1 }) =>
      getAskueEvents({ ...filters, sortBy, page_num: pageParam, obj_cnt: query_objects_limit }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.offset >= lastPage.count
          ? undefined
          : lastPage.offset / lastPage?.limit + 1;
      },
    },
  );
}

export function useAskueEnergy() {
  return useMutation<IAskueEnergyRes[], any, IAskueEnergyReq>((a) => getEnergyAskue(a), {
    mutationKey: [Keys.ASKUE, "energy"],
  });
}

export function useAskueEnergyChart() {
  return useMutation<IAskueChartRes, any, IAskueChartReq>((a) => getAskueEnergyChart(a), {
    mutationKey: [Keys.ASKUE, "chart"],
  });
}

export function useAskueReleLog() {
  return useMutation<IAskueReleLogRes[], any, { sn: string }>((a) => getAskueReleLog(a));
}

export function useAskueGetDeviceXlsx() {
  return useMutation<any, { description?: string }, IAskueRequest>(
    (data) => askueGetDeviceListXlsx(data),
    {
      onSuccess: async (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement("a");
        a.href = url;
        a.download = `askue_device_list.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
    },
  );
}

export function useGetMetersName() {
  return useQuery<any, { description?: string }, IAskueMeterNames>([Keys.ASKUE, "meter"], () =>
    getMetersName(),
  );
}

export function useAskueGetAbonReportXlsx() {
  return useMutation<any, { description?: string }, IAskueAbonReportRequest>(
    (data) => askueGetAbonReportXlsx(data),
    {
      onSuccess: async (res) => {
        const url = window.URL.createObjectURL(res);
        const a = document.createElement("a");
        a.href = url;
        a.download = `askue_abonent_report.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
    },
  );
}
