import styled from "@emotion/styled";
import { device } from "theme";

export const SBtns = styled.div`
  display: flex;
  align-items: end;
  flex: 1;
  min-height: 56px;
  width: 100%;

  @media ${device.tabletPlus} {
    width: inherit;
    flex: inherit;
  }

  & .MuiButton-root {
    width: 100%;
    & span {
      white-space: nowrap;
    }
  }
`;

export const SForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  & .devices {
    flex: 1;

    @media ${device.tabletPlus} {
      flex: 0 1 220px;
    }
  }

  & .diskret {
    flex: 1;

    @media ${device.tabletPlus} {
      flex: 0 1 220px;
    }
  }

  & .period {
    flex: 1;

    @media ${device.tabletPlus} {
      flex: 0 1 400px;
    }
  }

  @media ${device.tabletPlus} {
    flex-direction: row;
    align-items: start;
  }
`;

export const SPos = styled.div`
  display: flex;
  align-items: center;

  & > .MuiTypography-root {
    margin-right: 16px;
  }

  & > div {
    flex: 1;
    width: 100%;
    min-width: 100px;

    @media ${device.tabletPlus} {
      width: 160px;
    }
  }
`;

export const SDash = styled.form`
  padding: 0 8px;
`;
