import { useContext, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import format from "date-fns/format";

import { ContextSettings } from "context/context-settings";
import { Breadcrumbs } from "components/breadcrumbs/breadcrumbs";
import { Footer } from "components/footer/footer";
import { AnomaliesCard } from "components/anomalies-card/anomalies-card";
import { IFilter, TableFilter } from "components/table/table-filter/table-filter";
import { Loader } from "components/loader/loader";
import { Spacer } from "components/spacer/spacer.component";
import { InputDatePicker } from "components/mts-datepicker/mts-datepicker";
import { useDeviceList, useListEffeciency } from "hooks/api/anomalies";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { IDevlistReq } from "interface/anomalies";
import { CusTypo } from "components/cusTypo/custom-typography";
import { NotifyBlock } from "components/notification-block/notification-block";
import {
  defaultPeriod,
  EFF_HEADERS,
  FILTER_BY_STATUS,
  links,
  makeCards,
  RES_COLUMN,
  SCHEME_ANOMALIES,
  TABLE_FILTER_DEFAULT_ANOMALIES,
} from "./const";
import { SAnomalies, SEff, SGrid, SPerc, SSection } from "./styles";
import { SDash, SItem, SPos } from "pages/askue/styles";

export const AnomaliesPage = () => {
  const { isDesktop } = useContext(ContextSettings);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [cardsFilter, setCardsFilter] = useState<any>([]);
  const [period, setPeriod] = useState<IDevlistReq>(defaultPeriod);

  const queryCells = useDeviceList(period);
  const deviceList = queryCells?.data ?? [];
  const { data: effeciency, mutate: effMutate, isLoading, isSuccess } = useListEffeciency();

  const filteredDevices = deviceList.filter((a) => {
    const priemVals = cardsFilter?.find((b) => b.column === RES_COLUMN);
    let priemResult = isEmpty(priemVals?.value) ? true : priemVals?.value.includes(a.priem);
    return priemResult;
  });

  const anomaliesCards = makeCards(filteredDevices) ?? [];

  const isFilterOn = StorageAPI.get(EStorageKeys.ANMS_FILTER_IS) === "on";
  const isArchiveOn = StorageAPI.get(EStorageKeys.ANMS_ARCHIVE_IS) === "on";
  const storageFilter: IFilter =
    (StorageAPI.get(EStorageKeys.ANMS_FILTER) &&
      JSON.parse(StorageAPI.get(EStorageKeys.ANMS_FILTER))) ||
    [];

  const isStorageFilterAvail =
    isFilterOn && storageFilter && storageFilter?.some((a) => !isEmpty(a.value));

  const toggleOnFilter = () => {
    const localStatus = StorageAPI.get(EStorageKeys.ANMS_FILTER_IS);
    const res = !localStatus ? "on" : localStatus === "on" ? "off" : "on";
    StorageAPI.save(EStorageKeys.ANMS_FILTER_IS, res);
  };

  const setAnomaliesColumnFilter = (text) => {
    const res = [{ column: FILTER_BY_STATUS, value: [text] }];
    let newStorage = [...storageFilter, ...res];
    StorageAPI.save(EStorageKeys.ANMS_FILTER, JSON.stringify(newStorage));
    if (!isFilterOn) StorageAPI.save(EStorageKeys.ANMS_FILTER_IS, "on");
    if (!isArchiveOn) StorageAPI.save(EStorageKeys.ANMS_ARCHIVE_IS, "on");
  };

  const handleColumnFilter = ({ filtersArr }) => {
    if (!isEmpty(filtersArr) && isFilterOn) {
      StorageAPI.save(EStorageKeys.ANMS_FILTER, JSON.stringify(filtersArr));
    }
    setCardsFilter(filtersArr);
  };

  const removeStorageFilter = () => {
    StorageAPI.remove(EStorageKeys.ANMS_FILTER);
  };

  const handleDatesPeriod = (e, key) => {
    setPeriod((s) => ({ ...s, [key]: e ? format(new Date(e), "yyyy-MM-dd") : undefined }));
  };

  useEffect(() => {
    effMutate(period);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period]);

  useEffect(() => {
    if (storageFilter) {
      let newStorage = storageFilter?.filter((a) => a.column !== FILTER_BY_STATUS);
      StorageAPI.save(EStorageKeys.ANMS_FILTER, JSON.stringify(newStorage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(storageFilter)]);

  return (
    <>
      <SSection>
        <Breadcrumbs links={links} />
        <SAnomalies>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
            Оценка эффективности
          </CusTypo>
          <SItem>
            <SPos>
              <CusTypo variant="p4_medium">Период</CusTypo>
              <InputDatePicker
                placeholder="ДД.ММ.ГГ"
                onSelect={(e) => handleDatesPeriod(e, "dt_from")}
                isPickAM
              />
              <SDash>
                <CusTypo variant="p4_bold">-</CusTypo>
              </SDash>
              <InputDatePicker
                placeholder="ДД.ММ.ГГ"
                onSelect={(e) => handleDatesPeriod(e, "dt_to")}
                isPickAM
              />
            </SPos>
          </SItem>
        </SAnomalies>
        {isLoading ? (
          <div style={{ height: 100 }}>
            <Loader />
          </div>
        ) : isSuccess && effeciency != null ? (
          <>
            <SEff>
              <section>
                {Object.entries(effeciency).map(([key, val]) => (
                  <div key={val}>
                    <CusTypo variant={isDesktop() ? "p3_regular" : "c1_regular"}>
                      {EFF_HEADERS[key]}
                    </CusTypo>
                    <SPerc variant={isDesktop() ? "h2_medium" : "h3_medium"} font="wide">
                      {val}%
                    </SPerc>
                  </div>
                ))}
              </section>
            </SEff>
          </>
        ) : (
          <NotifyBlock content="Нет данных" status="error" />
        )}
        <Spacer value={isDesktop() ? "64px" : "48px"} />
        <SAnomalies isRowMob>
          <CusTypo variant={isDesktop() ? "h2_medium" : "h4_medium"} font="wide">
            Актуальная информация
          </CusTypo>
          <TableFilter
            dataLength={filteredDevices.length}
            isOpen={isOpenFilter}
            isOn={isFilterOn}
            toggleSwitch={toggleOnFilter}
            defaultFilter={
              isStorageFilterAvail
                ? storageFilter.reduce((total, amount) => {
                    return { ...total, [amount.column]: amount.value };
                  }, {})
                : TABLE_FILTER_DEFAULT_ANOMALIES
            }
            toogleShowFilter={setIsOpenFilter}
            onApplyFilter={handleColumnFilter}
            onResetFilter={removeStorageFilter}
            scheme={SCHEME_ANOMALIES}
          />
        </SAnomalies>
        {queryCells.isLoading ? (
          <Loader isFullPage />
        ) : queryCells.isSuccess && deviceList.length ? (
          <>
            <SGrid>
              {anomaliesCards.map((item) => (
                <AnomaliesCard
                  key={item.title}
                  title={item.value}
                  onClick={() => setAnomaliesColumnFilter(item.value)}
                  number={item.number}
                  variant={item.variant}
                />
              ))}
            </SGrid>
          </>
        ) : queryCells.isError && queryCells.error?.description !== null ? (
          <NotifyBlock
            content="Ваши приборы учета под защитой. На данный момент аномалии не обнаружены, список анализируется и формируется."
            status="success"
          />
        ) : (
          <NotifyBlock
            content="Неизвестная ошибка запроса данных. Попробуйте перезагрузить страницу или зайдите позже"
            status="error"
          />
        )}
      </SSection>
      <Footer />
    </>
  );
};
