import { IHttpMethods } from "stream-constants/server-constants";
import { EStorageKeys, StorageAPI } from "utils/localStorageAPI";
import { fetchData } from "services/services-utils/fetch-data";
import {
  COLUMNS_CHECK,
  COLUMNS_LOAD,
  COLUMNS_SAVE,
  FAV_SINGLE,
  FAVLIST_DEL,
  FAVLIST_EDIT,
  FAVS_LIST,
  FILTER_LOAD,
  FILTER_SAVE,
  FIND_ON_MAP,
  HANDBOOK,
  RESEARCH_REP_DASH,
  RESEARCH_GET_CHART,
  RESEARCH_NEW,
  RESEARCH_SET_TICKET,
  RESEARCH_REP_LIST,
  RESEARCH_SET_STATUS,
  RESEARCH_SET_FB,
  RESEARCH_SET_BD,
  RESEARCH_SET_T3,
  RESEARCH_SET_T2,
  STAT_RES,
  STAT_INFO,
  RESEARCH_STATS_ARCHIVE,
  FIND_GEO,
  RESEARCH_SET_T0,
} from "services/urls";

import { chart_data } from "services/demo/mock-data";
import {
  IEditFavlistReq,
  IResearchChartReq,
  IResearchGeoReq,
  IResearchMapReq,
  IResearchRequest,
  IResearchSetStatusReq,
  IResearchSetTicketReq,
} from "interface/analytics";

export const getResearchChart = async (data: IResearchChartReq) => {
  let result;
  const IS_SERVER_MODE_MOCKED = StorageAPI.get(EStorageKeys.MOCKS_STATUS) === "on";

  if (IS_SERVER_MODE_MOCKED) {
    result = chart_data;
  } else {
    result = await fetchData({
      url: RESEARCH_GET_CHART,
      method: IHttpMethods.POST,
      name: "getResearchChartData",
      body: JSON.stringify(data),
      emptyResponse: false,
    });
  }

  return result;
};

export const analSetStatus = async (data: IResearchSetStatusReq) => {
  return fetchData({
    url: RESEARCH_SET_STATUS,
    method: IHttpMethods.POST,
    name: "analSetStatus",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const analSetTicket = async (data: IResearchSetTicketReq) => {
  return fetchData({
    url: RESEARCH_SET_TICKET,
    method: IHttpMethods.POST,
    name: "analSetTicket",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const analSetFb = async () => {
  return fetchData({
    url: RESEARCH_SET_FB,
    method: IHttpMethods.POST,
    name: "analSetFb",
    emptyResponse: false,
  });
};

export const analSetBd = async () => {
  return fetchData({
    url: RESEARCH_SET_BD,
    method: IHttpMethods.POST,
    name: "analSetBd",
    emptyResponse: false,
  });
};

export const analSetT2 = async (season: 1 | 2) => {
  return fetchData({
    url: `${RESEARCH_SET_T2}/${season}`,
    method: IHttpMethods.POST,
    name: "analSetT2",
    emptyResponse: false,
  });
};

export const analSetT3 = async () => {
  return fetchData({
    url: RESEARCH_SET_T3,
    method: IHttpMethods.POST,
    name: "analSetT3",
    emptyResponse: false,
    responseAbordTimeOut: false,
  });
};

export const analSetT0 = async () => {
  return fetchData({
    url: RESEARCH_SET_T0,
    method: IHttpMethods.POST,
    name: "analSetT0",
    emptyResponse: false,
    responseAbordTimeOut: false,
  });
};

export const researchReportDashboard = async () => {
  return await fetchData({
    url: `${RESEARCH_REP_DASH}/1`,
    method: IHttpMethods.GET,
    name: "researchReportDashboard",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};

export const researchReportList = async (subpath: number | string) => {
  return await fetchData({
    url: `${RESEARCH_REP_LIST}/${subpath}`,
    method: IHttpMethods.GET,
    name: "researchReportList",
    emptyResponse: false,
    blobResponse: true,
    responseAbordTimeOut: false,
  }).then((blob) => {
    return blob;
  });
};

export const researchFindOnMap = async (data?: IResearchMapReq) => {
  let result;
  result = await fetchData({
    url: FIND_ON_MAP,
    method: IHttpMethods.POST,
    name: "researchFindOnMap",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
  return result;
};

export const researchFindGeo = async (data?: IResearchGeoReq) => {
  let result;
  result = await fetchData({
    url: FIND_GEO,
    method: IHttpMethods.POST,
    name: "researchFindGeo",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
  return result;
};

export const researchFavsList = async () => {
  return await fetchData({
    url: FAVS_LIST,
    method: IHttpMethods.GET,
    name: "researchFavsList",
    emptyResponse: false,
  });
};

export const researchSingleFavlist = async (id: string | number) => {
  return await fetchData({
    url: `${FAV_SINGLE}/${id}`,
    method: IHttpMethods.GET,
    name: "researchSingleFavlist",
    emptyResponse: false,
  });
};

export const researchEditFavlist = async (data?: IEditFavlistReq) => {
  let result;
  result = await fetchData({
    url: FAVLIST_EDIT,
    method: IHttpMethods.POST,
    name: "researchEditFavlist",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
  return result;
};

export const researchDeleteFavlist = async (id: number | string) => {
  const result = fetchData({
    url: `${FAVLIST_DEL}/${id}`,
    method: IHttpMethods.DELETE,
    name: "researchDeleteFavlist",
    emptyResponse: false,
  });

  return result;
};

export const researchHandbook = async (subpath: string) => {
  return await fetchData({
    url: `${HANDBOOK}/${subpath}`,
    method: IHttpMethods.GET,
    name: "researchHandbook",
    emptyResponse: false,
  });
};

export const researchSaveFilter = async (data: any) => {
  let result;
  result = await fetchData({
    url: FILTER_SAVE,
    method: IHttpMethods.POST,
    name: "researchSaveFilter",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
  return result;
};

export const researchLoadFilter = async () => {
  return await fetchData({
    url: FILTER_LOAD,
    method: IHttpMethods.GET,
    name: "researchLoadFilter",
    emptyResponse: false,
  });
};

export const researchSaveColumns = async (data: any) => {
  let result;
  result = await fetchData({
    url: COLUMNS_SAVE,
    method: IHttpMethods.POST,
    name: "researchSaveFilter",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
  return result;
};

export const researchLoadColumns = async () => {
  return await fetchData({
    url: COLUMNS_LOAD,
    method: IHttpMethods.GET,
    name: "researchLoadFilter",
    emptyResponse: false,
  });
};

export const researchCheckVisibleColumns = async () => {
  return await fetchData({
    url: COLUMNS_CHECK,
    method: IHttpMethods.GET,
    name: "researchCheckVisibleColumns",
    emptyResponse: false,
  });
};

export const getResearchDevice = async (data?: IResearchRequest) => {
  return await fetchData({
    url: RESEARCH_NEW,
    method: IHttpMethods.POST,
    name: "getResearchDevice",
    body: JSON.stringify(data),
    emptyResponse: false,
  });
};

export const getAnalStatRes = async () => {
  return await fetchData({
    url: STAT_RES,
    method: IHttpMethods.GET,
    name: "getAnalStatRes",
    emptyResponse: false,
  });
};

export const getAnalStatInfo = async () => {
  return await fetchData({
    url: STAT_INFO,
    method: IHttpMethods.GET,
    name: "getAnalStatInfo",
    emptyResponse: false,
  });
};

export const getAnalStatsArchive = async (id: string | number) => {
  return await fetchData({
    url: `${RESEARCH_STATS_ARCHIVE}/${id}`,
    method: IHttpMethods.GET,
    name: "getAnalStatsArchive",
    emptyResponse: false,
  });
};
