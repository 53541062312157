import styled from "@emotion/styled";
import { CusTypo } from "components/cusTypo/custom-typography";

export const SFormField = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const SBtns = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
  margin-left: 0;

  & .MuiButtonBase-root {
    min-width: 287px;
  }
`;

export const STop = styled.div`
  text-align: left;
`;

export const SSubtitle = styled(CusTypo)`
  color: ${(prop) => prop.theme.mtsColor.text.secondary.lightMode};
`;

export const SDelIcon = styled.div<{ isError: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px 4px;
  cursor: pointer;
  height: ${(props) => (props.isError ? "64px" : `44px`)};
  position: relative;
  top: ${(props) => (props.isError ? "0" : `12px`)};

  & > svg {
    color: ${(props) => props.theme.mtsColor.icons.secondary.lightMode};
    font-size: 24px;
  }
`;

export const SInp = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const SAddNew = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;
