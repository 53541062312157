export const ROUTE_WELCOME_PAGE = "/";
export const ROUTE_PERSONAL_AREA = "lk";
export const ROUTE_LANDINGS = `https://${window.location.hostname.replace("lk.", "")}`;

export const ROUTE_ANOMALIES = `${ROUTE_PERSONAL_AREA}/anomalies`;
export const ROUTE_DEVICE_LIST = "device-list";
export const ROUTE_DEVICE_LIST_PAGE = `${ROUTE_ANOMALIES}/device-list`;
export const ROUTE_DEVICE_ITEM = `${ROUTE_DEVICE_LIST_PAGE}/:id`;
export const ROUTE_DEVICE_UPDATE = `${ROUTE_DEVICE_LIST_PAGE}/:id/update`;

export const ROUTE_MANAGEMENT = `${ROUTE_PERSONAL_AREA}/management`;
export const ROUTE_BILLING_PAGE = `${ROUTE_MANAGEMENT}/billing`;
export const ROUTE_TENANT_PAGE = `${ROUTE_MANAGEMENT}/tenant`;
export const ROUTE_TENANT_ITEM = `${ROUTE_MANAGEMENT}/tenant/:id`;
export const ROUTE_USERS = `${ROUTE_MANAGEMENT}/users`;
export const ROUTE_USERS_ITEM = `${ROUTE_MANAGEMENT}/users/:id`;
export const ROUTE_ASKUE_MANAGEMENT = `${ROUTE_MANAGEMENT}/askue`;
export const ROUTE_DEMO_MANAGEMENT = `${ROUTE_MANAGEMENT}/demo`;
export const ROUTE_EVENTS = `${ROUTE_MANAGEMENT}/events`;
export const ROUTE_SHOP_MANAGEMENT = `${ROUTE_MANAGEMENT}/shop`;
export const ROUTE_TARIFF = `${ROUTE_MANAGEMENT}/tariff`;
export const ROUTE_ABONENT_MANAGEMENT = `${ROUTE_MANAGEMENT}/abonents`;
export const ROUTE_ABONENT_ITEM = `${ROUTE_MANAGEMENT}/abonents/:id`;

export const ROUTE_ANALYTICS = "analytics";
export const ANAL_RESEARCH = "research";
export const ANAL_HANDBOOK = "handbook";
export const ANAL_STAT = "stat";
export const ROUTE_ANALYTICS_PAGE = `${ROUTE_PERSONAL_AREA}/${ROUTE_ANALYTICS}`;
export const ROUTE_RESEARCH_PAGE = `${ROUTE_ANALYTICS_PAGE}/${ANAL_RESEARCH}`;
export const ROUTE_RESEARCH_GEO_PAGE = `${ROUTE_ANALYTICS_PAGE}/${ANAL_RESEARCH}/:id`;
export const ROUTE_HANDBOOK = `${ROUTE_ANALYTICS_PAGE}/${ANAL_HANDBOOK}`;
export const ROUTE_ANAL_STAT = `${ROUTE_ANALYTICS_PAGE}/${ANAL_STAT}`;

export const ROUTE_ASKUE_PAGE = `${ROUTE_PERSONAL_AREA}/askue`;
export const ROUTE_ASKUE_DEVICES = `${ROUTE_ASKUE_PAGE}/devices`;
export const ROUTE_ASKUE_EVENTS = `${ROUTE_ASKUE_PAGE}/events`;
export const ROUTE_ASKUE_INSTRUCTIONS = `${ROUTE_ASKUE_PAGE}/instructions`;

export const ROUTE_DEPARTURES = `${ROUTE_PERSONAL_AREA}/departures`;
export const ROUTE_DEPARTURES_MAP = `${ROUTE_DEPARTURES}/map`;

export const ROUTE_MINING = `${ROUTE_PERSONAL_AREA}/mining`;
export const ROUTE_MINING_MAP = `${ROUTE_MINING}/map`;
export const ROUTE_MINING_ARCHIVE = `${ROUTE_MINING}/archive`;

export const ROUTE_DEMO = `${ROUTE_PERSONAL_AREA}/demo`;
export const ROUTE_USER_AGREEMENT = "user-agreement";
export const ROUTE_SECURITY = "security";
export const ROUTE_LANDING = "landing";
