import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ITableFilterClm, ITableSortColumn, Table } from "components/table/table.component";
import { IMngAbonentResponse } from "interface/management";
import { ROUTE_ABONENT_MANAGEMENT } from "stream-constants/route-constants";
import { ContextSettings } from "context/context-settings";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { tableMobStructure, tableStructure } from "./const";
import { constructColumns, constructTableData } from "./utils";

interface IProp {
  rawData: IMngAbonentResponse[];
  filterByColumn?: ITableFilterClm[];
  globalFilter?: string;
  onRowsChange?: Function;
  sortByColumn?: ITableSortColumn[];
  savedId?: number;
}
export const AbonentsTable: React.FC<IProp> = ({
  rawData,
  filterByColumn,
  globalFilter,
  onRowsChange,
  sortByColumn,
  savedId,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const navigate = useNavigate();
  const [active, setActive] = useState({});
  const tableData = useMemo(() => constructTableData(rawData ?? [{}]), [rawData]);
  const columns = useMemo(() => constructColumns({ columns: tableStructure, active }), [active]);
  const mobileColumns = useMemo(() => tableMobStructure, []);

  const clickDesktop = useCallback((id) => setActive((a) => ({ [id]: !a[id] })), []);
  const clickMobile = useCallback(
    (id) => navigate(`/${ROUTE_ABONENT_MANAGEMENT}/${id}`),
    [navigate],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  return isDesktop() ? (
    <div style={{ padding: "0 32px" }}>
      <Table
        view="expandable"
        columns={columns}
        data={tableData}
        globalFilter={globalFilter}
        height={516}
        filterByColumn={filterByColumn}
        onRowClick={clickDesktop}
        onRowsChange={handleRowsChange}
        savedId={savedId}
      />
    </div>
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      filterByColumn={filterByColumn}
      sortByColumn={sortByColumn}
      onRowClick={clickMobile}
      onRowsChange={handleRowsChange}
      globalFilter={globalFilter}
      padding="0 20px"
    />
  );
};
