import { useMemo, useCallback, useContext } from "react";
import { SortingRule } from "react-table";

import { ContextSettings } from "context/context-settings";
import { constructColumns } from "./utils";
import { IAskueEvent } from "interface/askue";
import { ITableSortColumn, Table } from "components/table/table.component";
import { MobileTable } from "components/table/mobile-table/mobileTable.component";
import { tableMobStructure, tableStructure } from "./const";

interface IProp {
  rawData: IAskueEvent[];
  height?: number;
  globalFilter?: string;
  onRowsChange?: Function;
  onEndReach?: Function;
  sortBy?: SortingRule<ITableSortColumn>[];
  onSortChange?: Function;
}
export const AskueEventsTable: React.FC<IProp> = ({
  rawData,
  height,
  globalFilter,
  onRowsChange,
  onEndReach,
  sortBy,
  onSortChange,
}) => {
  const { isDesktop } = useContext(ContextSettings);
  const mobileColumns = useMemo(() => tableMobStructure, []);
  const tableData = useMemo(() => rawData ?? [{}], [rawData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRowsChange = useCallback((a) => onRowsChange?.(a), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInfiniteLoading = useCallback(() => onEndReach, []);

  const columns = useMemo(
    () =>
      constructColumns({
        columns: tableStructure,
      }),
    [],
  );

  const defaultSortBy = useMemo(
    () => [
      {
        id: "datetime",
        desc: true,
      },
    ],
    [],
  );

  return isDesktop() ? (
    <div style={{ padding: "0 32px" }}>
      <Table
        columns={columns}
        data={tableData}
        height={height}
        view="alternatingRows"
        onRowsChange={handleRowsChange}
        onEndReach={handleInfiniteLoading}
        sortBy={sortBy && sortBy.length ? sortBy : defaultSortBy}
        onSortByChange={(newSortBy) => onSortChange?.(newSortBy)}
      />
    </div>
  ) : (
    <MobileTable
      columns={mobileColumns}
      data={tableData}
      globalFilter={globalFilter}
      height={height}
      padding="0 20px"
    />
  );
};
