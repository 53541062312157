import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";

import { useManagementAbonents } from "hooks/api/management";
import { Loader } from "components/loader/loader";
import { Back } from "components/back/back.component";
import { Spacer } from "components/spacer/spacer.component";
import { CusTypo } from "components/cusTypo/custom-typography";
import { MobAbonent } from "components/card-info/abonent/mobAbonents.component";
import { SItemPage } from "components/card-info/styles";

// NOTE: Страница имеет только мобильную верстку
export const AbonentItemPage = () => {
  let { id } = useParams();
  const queryUsers = useManagementAbonents();
  const selected = queryUsers?.data?.find((a) => `${a.user_id}` === id);

  return (
    <>
      <SItemPage>
        <Back />
        <Spacer value="28px" />
        {selected && !isEmpty(selected) ? (
          <MobAbonent data={selected} />
        ) : (
          <CusTypo variant="p3_regular">Нет данных</CusTypo>
        )}
        {queryUsers.isLoading ? <Loader isFullPage /> : null}
      </SItemPage>
    </>
  );
};
